import React, {Fragment, useImperativeHandle, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Text from '@component/Text/Text'
import {TypeContentModalSize} from '@component/modal/WizContentModal'
import IconModalClose from '@svg/common/ic_modal_close.svg'
import WizIcon from '@component/images/WizIcon'

interface IProps {
    className?: string
    title?: string
    titleIcon?: React.ReactNode
    children?: React.ReactNode
    size?: TypeWizPopupSize
    onCloseCallback?: () => void
    hideTitle?: boolean
    hideClose?: boolean
    layoutClassName?: string
    popupClassName?: string
    titleClassName?: string
}

export type TypeWizPopupSize = '340' | '400' | '600' | '800'

interface IPopupStyle {
    widthClassName?: string
}

const styleMap = new Map<TypeContentModalSize, IPopupStyle>()
styleMap.set('340', {widthClassName: 'max-w-[340px] '})
styleMap.set('400', {widthClassName: 'max-w-[400px] '})
styleMap.set('600', {widthClassName: 'max-w-[600px] '})
styleMap.set('800', {widthClassName: 'max-w-[800px] '})

const WizPopupModal = (
    {
        className,
        title,
        titleIcon,
        children,
        size = '600',
        onCloseCallback,
        hideTitle = false,
        hideClose = false,
        layoutClassName = '',
        popupClassName = '',
        titleClassName = '',
    }: IProps,
    ref: any,
) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
    }

    const showModal = () => {
        setIsOpen(true)
    }

    return (
        <>
            <Dialog open={isOpen} as="div" className="relative z-modal" unmount={true} onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[3px]" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div
                        className={`flex flex-col min-h-full items-center justify-center md:mx-[10px] ${layoutClassName}`}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className={`bg-white dark:bg-bg_dark_popup ${
                                    styleMap?.get(size)?.widthClassName
                                } min-h-[300px] w-full max-h-[615px] px-[30px] py-[20px] rounded-[5px] sm:w-[320px] sm:px-[20px]  ${popupClassName}`}>
                                {!hideTitle && (
                                    <div className={'flex items-center justify-between'}>
                                        <div className={'flex items-center gap-x-[5px]'}>
                                            {titleIcon}
                                            <Text
                                                className={`flex-1 text-gray01 dark:text-dark_gray01 text-ti1 sm:text-h4 ${titleClassName}`}>
                                                {title}
                                            </Text>
                                        </div>

                                        {!hideClose && (
                                            <div
                                                className={'cursor-pointer'}
                                                onClick={() => {
                                                    closeModal()
                                                }}>
                                                <WizIcon
                                                    backgroundColorClassName={
                                                        'p-[5px] fill-gray01 dark:fill-dark_gray01 hover:fill-gray01 dark:hover:fill-dark_gray01 hover:bg-transparent01 dark:hover:bg-dark_transparent01 cursor-pointer'
                                                    }>
                                                    <IconModalClose
                                                        className={'w-[20px] fill-gray01 dark:fill-dark_gray01'}
                                                    />
                                                </WizIcon>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={`${className}`}>{children}</div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default React.forwardRef(WizPopupModal)
