import React, {useEffect, useRef} from 'react'
import {useLoginModalStore} from '@store/LoginModalStore'
import {shallow} from 'zustand/shallow'
import LoginModal from '@feature/common/components/LoginModal/LoginModal'
import AlertModal from '@component/modal/AlertModal'
import {useAlertModalStore} from '@store/AlertModalStore'
import PendingInvoiceModal from '@feature/common/components/PendingInvoiceModal/PendingInvoiceModal'
import {useLoginStore} from '@store/LoginStore'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import useQueryGetTodayDailyReport from '@hook/query/user/account/report/useQueryGetTodayDailyReport'
import FirstJoinDailyReportModal from '@feature/common/components/DailyReportModal/FirstJoinDailyReportModal'
import useQueryGetPoint from '@hook/query/account/point/useQueryGetPoint'
import MemePhishingSiteModal from '@feature/meme/MemePhishingSiteModal/MemePhishingSiteModal'
import {useRouter} from 'next/router'

const ModalContainer = () => {
    const router = useRouter()
    const {setRedirectUrl} = useLoginModalStore(state => ({
        setRedirectUrl: state.setRedirectUrl,
    }))

    const id = useLoginStore(state => state.id)
    const {} = useQueryGetPoint(true)

    const {isVisibleLoginModal, hideLoginModal} = useLoginModalStore(
        state => ({
            isVisibleLoginModal: state.isVisibleLoginModal,
            hideLoginModal: state.hideLoginModal,
        }),
        shallow,
    )

    const {alertModal, setAlertModal} = useAlertModalStore(
        state => ({
            alertModal: state.alertModal,
            setAlertModal: state.setAlertModal,
        }),
        shallow,
    )

    const loginModalRef = useRef(null)
    const AlertModalRef = useRef(null)
    const phishingModalRef = useRef(null)

    const {data} = useQueryFetchMe(undefined, false)
    const {data: dailyReport} = useQueryGetTodayDailyReport()

    const pendingInvoiceModalRef = useRef<any>()
    const dailyReportModalRef = useRef<any>()

    useEffect(() => {
        if (isVisibleLoginModal) {
            loginModalRef?.current?.show()
        } else {
            loginModalRef?.current?.close()
        }
    }, [isVisibleLoginModal])

    useEffect(() => {
        if (alertModal) {
            AlertModalRef?.current?.show()
        } else {
            setAlertModal(undefined)
        }
    }, [alertModal, setAlertModal])

    useEffect(() => {
        localStorage.removeItem('isCheckInvoiceModal')
    }, [])
    useEffect(() => {
        const isCheckPendingInvoice = localStorage.getItem('isCheckInvoiceModal')

        if (!isCheckPendingInvoice && data?.pending_invoices > 0 && id === data?.id) {
            localStorage.setItem('isCheckInvoiceModal', 'true')
            setTimeout(() => {
                pendingInvoiceModalRef?.current?.show()
            }, 500)
        }
        if (isCheckPendingInvoice && !id) {
            localStorage.removeItem('isCheckInvoiceModal')
        }
    }, [id, data])

    useEffect(() => {
        if (
            dailyReport &&
            data?.preferences?.portfolio_msg_enabled !== false &&
            dailyReport?.created_at &&
            data?.id === dailyReport?.user_id
        ) {
            if (
                !localStorage.getItem(`todayDailyReport_${data?.id}`) ||
                localStorage.getItem(`todayDailyReport_${data?.id}`) !==
                    `${dailyReport?.user_id}_${dailyReport?.created_at}`
            ) {
                localStorage.setItem(
                    `todayDailyReport_${data?.id}`,
                    `${dailyReport?.user_id}_${dailyReport?.created_at}`,
                )
                dailyReportModalRef?.current?.show()
            }
        }
    }, [dailyReport, data])

    useEffect(() => {
        if (router?.asPath === '/meme') {
            if (localStorage && !localStorage?.getItem('not_show_meme_phishing_modal')) {
                phishingModalRef?.current?.show()
            }
        }
    }, [router?.asPath])

    return (
        <>
            <LoginModal
                ref={loginModalRef}
                onClose={() => {
                    hideLoginModal()
                    setRedirectUrl(undefined)
                }}
            />
            <AlertModal ref={AlertModalRef} />
            <PendingInvoiceModal ref={pendingInvoiceModalRef} id={id} />
            <FirstJoinDailyReportModal ref={dailyReportModalRef} />
            <MemePhishingSiteModal ref={phishingModalRef} />
        </>
    )
}

export default ModalContainer
