import React, {useEffect, useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import {IImportantAlerts} from '@hook/useImportantAlerts'
import WizButton from '@component/buttons/WizButton'
import WizPopupModal from '@component/modal/WizPopupModal'

interface IProps {
    alertList: IImportantAlerts[]
}

const AccountImportantAlertModal = ({alertList}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    useImperativeHandle(ref, () => ({
        show: async () => {
            modalRef.current?.show()
        },
    }))

    useEffect(() => {
        if (alertList?.length === 0) {
            modalRef?.current?.close()
        }
    }, [alertList])

    return (
        <>
            {/* 다중 알림 Modal 제작 */}
            <WizPopupModal
                size={'600'}
                titleClassName={'!text-h3'}
                title={t('modal.accountAlert.title')}
                popupClassName={`${alertList?.length <= 1 ? 'min-h-full' : ''} sm:w-full sm:max-h-[550px]`}
                ref={modalRef}>
                <div className={'pt-[30px] pb-[10px] sm:pb-0'}>
                    <article className={'max-h-[430px] sm:max-h-[365px] overflow-y-auto not_bg_scrollbar'}>
                        {alertList?.map((item, idx) => (
                            <section
                                key={idx}
                                className={`py-[20px] px-[10px] sm:px-[5px] gap-x-[10px] first:pt-[0px] flex items-center border-b border-gray08 dark:border-dark_gray08 ${
                                    alertList?.length > 2 ? 'last:border-none' : ''
                                }`}>
                                <div className={'grow'}>{item?.modalBoxView}</div>
                                <WizButton
                                    size={'small'}
                                    buttonType={'primary'}
                                    className={'w-[90px]'}
                                    onClick={() => {
                                        modalRef?.current?.close()
                                        item?.action()
                                    }}
                                    text={item?.actionText}
                                />
                            </section>
                        ))}
                    </article>
                </div>
            </WizPopupModal>
        </>
    )
}

export default React.forwardRef(AccountImportantAlertModal)
