import BigNumber from 'bignumber.js'
import {useRouter} from 'next/router'
import {ENGLISH_SUFFICE, KOREAN_SUFFICE} from '@constant/number'
import {CurrencyDisplayMap, FormatCurrencyNumberOptions} from '@util/numbers'
import {useCurrencyStore} from '@store/CurrencyStore'
import {shallow} from 'zustand/shallow'
import {equalString} from '@util/strings'

export interface IDisplayBalanceOptions {
    showPostSymbol?: boolean
    showPreSign?: boolean
    isShortForm?: boolean
    isUSD?: boolean
    isApproximately?: boolean
    isAbsolute?: boolean
    isShowMinusSymbol?: boolean
    isDigit?: boolean
    isShowFullNumber?: boolean
}

export interface IDisplayPriceOptions {
    showPostSymbol?: boolean
    showPreSign?: boolean
    currencySymbol?: string
    isApproximately?: boolean
}

const useFormatNumber = () => {
    const language = useRouter().locale
    const {selectedSymbol, getSelectedCurrency, getCurrency} = useCurrencyStore(
        state => ({
            selectedSymbol: state.selectedSymbol,
            getSelectedCurrency: state.getSelectedCurrency,
            getCurrency: state.getCurrency,
        }),
        shallow,
    )

    const calcPrice = (n: number | BigNumber) => {
        if (!n) {
            return 0
        }
        const v = getSelectedCurrency()?.value ?? 1
        if (v === 0) {
            return 0
        }
        return new BigNumber(n).div(v).toNumber()
    }

    const displayPrice = (number: number | BigNumber, options?: IDisplayPriceOptions) => {
        const getUnicode = () => {
            if (equalString(options?.currencySymbol, 'usd')) {
                return getCurrency('USD')?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        return formatCurrencyNumber({
            number: equalString(options?.currencySymbol, 'usd') ? number : calcPrice(number),
            currencySymbol: options?.currencySymbol ? options?.currencySymbol : selectedSymbol,
            isPrice: true,
            currencyUnicode: options?.showPreSign ? getUnicode() : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: options?.isApproximately,
        })
    }

    const displayBalance = (number: number | BigNumber, options?: IDisplayBalanceOptions) => {
        const getUnicode = () => {
            if (options?.isUSD) {
                return getCurrency('USD')?.unicode
            } else {
                return getSelectedCurrency()?.unicode
            }
        }

        return formatCurrencyNumber({
            number: options?.isUSD ? number : calcPrice(number),
            currencySymbol: options?.isUSD ? 'USD' : getSelectedCurrency()?.symbol,
            isPrice: false,
            isShortForm: options?.isShortForm,
            currencyUnicode: options?.showPreSign ? getUnicode() : '',
            showPostSymbol: options?.showPostSymbol,
            isApproximately: options?.isApproximately,
            isAbsolute: options?.isAbsolute,
            isDigit: options?.isDigit,
            isShowFullNumber: options?.isShowFullNumber,
            isShowMinusSymbol:
                options?.isShowMinusSymbol === true || options?.isShowMinusSymbol === false
                    ? options?.isShowMinusSymbol
                    : true,
        })
    }

    const formatKM = (number: number | BigNumber): string => {
        const scaleLetter = language == 'ko' ? KOREAN_SUFFICE : ENGLISH_SUFFICE
        const scalePlace = language == 'ko' ? 4 : 3
        const placeNumber = 1

        const n = new BigNumber(number)
        const m = Math.floor((n.abs().e || 0) / scalePlace)
        if (m > 0) {
            const suffix = scaleLetter[m] ? scaleLetter[m] : ''
            const z = Math.pow(10, m * scalePlace)

            const formatted = n.dividedBy(z)

            return formatted.toFormat(placeNumber, BigNumber.ROUND_HALF_UP).toString() + suffix
        } else {
            return n.toFormat(placeNumber, BigNumber.ROUND_HALF_UP).toString()
        }
    }

    const formatCurrencyNumber = (options: FormatCurrencyNumberOptions = {}): string => {
        let numberText
        const {
            number = 0,
            currencySymbol = options.currencySymbol ?? 'USD',
            isPrice = false,
            isApproximately = false,
            isAbsolute = false,
            isDigit = true,
        } = options

        let n = new BigNumber(number).abs()

        if (isApproximately) {
            const divider = Math.pow(10, Math.floor(n.abs().e || 0))
            n = n.dividedToIntegerBy(divider).multipliedBy(divider)
        }

        if (options.isShortForm) {
            numberText = formatKM(n)
        } else {
            if (n.abs().isLessThan(1)) {
                let smallDigit
                if (!isDigit) {
                    smallDigit = 0
                } else if (isPrice) {
                    smallDigit = CurrencyDisplayMap.get(currencySymbol.toUpperCase())?.priceDecimalPlace.small ?? 8
                } else {
                    smallDigit = CurrencyDisplayMap.get(currencySymbol.toUpperCase())?.balanceDecimalPlace.small ?? 8
                }

                numberText = n.toFormat(smallDigit)
            } else if (n.abs().isGreaterThanOrEqualTo(1000000000.0)) {
                if (options?.isShowFullNumber) {
                    let digit
                    digit = CurrencyDisplayMap.get(currencySymbol.toUpperCase())?.balanceDecimalPlace.default ?? 8
                    numberText = n.toFormat(digit)
                } else {
                    numberText = formatKM(n)
                }
            } else {
                let digit
                if (!isDigit) {
                    digit = 0
                } else if (isPrice) {
                    digit = CurrencyDisplayMap.get(currencySymbol.toUpperCase())?.priceDecimalPlace.default ?? 8
                } else {
                    digit = CurrencyDisplayMap.get(currencySymbol.toUpperCase())?.balanceDecimalPlace.default ?? 8
                }
                numberText = n.toFormat(digit)
            }
        }

        let preText = ''
        if (options.currencyUnicode) {
            preText = `${options.currencyUnicode}`
        }

        let postText = ''
        if (options.showPostSymbol) {
            postText = ` ${currencySymbol.toUpperCase()}`
        }

        let approximatelySymbol = ''
        if (options.isApproximately) {
            approximatelySymbol = `≈`
        }

        let signSymbol = ''
        if (
            (typeof number === 'number' && number < 0 && isAbsolute !== true) ||
            (number instanceof BigNumber && number.isLessThan(0) && isAbsolute !== true)
        ) {
            if (options?.isShowMinusSymbol) {
                signSymbol = '-'
            }
        }

        return `${approximatelySymbol}${signSymbol}${preText}${numberText}${postText}`
    }

    const displayPercent = (profit?: number | BigNumber | string, digit: number = 2): string => {
        if (!profit && digit === 0) {
            return '0%'
        }
        if (!profit) {
            return '0.00%'
        }

        const n = new BigNumber(profit)
        //For amount if it's a big number no one really cares about decimal points.

        return `${n.toFormat(digit)}%`
    }

    const displayLeverage = (price?: number | BigNumber | string): string => {
        if (!price) {
            return '0'
        }
        const n = new BigNumber(price)
        if (n.isPositive() && n.isLessThanOrEqualTo(0.01)) {
            return n.toPrecision(3)
        }
        //For amount if it's a big number no one really cares about decimal points.
        if (n.abs().isGreaterThan(10000)) {
            return n.toFormat(0)
        }
        return n.decimalPlaces(2, 1).toFormat()
    }

    const displayAmount = (amount: number | BigNumber | string, price: number = 1000000): string => {
        let fixed = Math.floor(Math.log10(price))
        if (fixed < 2) {
            fixed = 2
        }

        const decimalPlaces = fixed
        return new BigNumber(amount).decimalPlaces(decimalPlaces).toFormat()
    }

    const displayPositionAmount = (amount: number | BigNumber | string, price: number = 1000000): string => {
        let fixed = Math.floor(Math.log10(price))
        if (fixed < 2) {
            fixed = 2
        }

        const decimalPlaces = fixed
        return Number(amount) < 0
            ? `(${new BigNumber(Math.abs(Number(amount))).decimalPlaces(decimalPlaces).toFormat()})`
            : new BigNumber(amount).decimalPlaces(decimalPlaces).toFormat()
    }

    const displayNumber = (number: number | BigNumber | string, digit?: number): string => {
        if (!number) return '0'

        return new BigNumber(number).toFormat(digit)
    }

    return {
        displayPrice,
        displayBalance,
        displayPercent,
        displayLeverage,
        displayPositionAmount,
        displayAmount,
        displayNumber,
    }
}

export default useFormatNumber
