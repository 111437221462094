import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import useFormatNumber from '@hook/useFormatNumber'
import {getNumberColorClass} from '@util/etc'
import Span from '@component/Text/Span'
import TextHighlight from '@feature/portfolio/components/TextHighlight/TextHighlight'
import {IAnalytics} from '@type/profile'
import Info from '@svg/common/ic_info.svg'
import IconInfoDark from '@svg/common/ic_info_dark.svg'
import TagText from '@component/Text/TagText'
import WizTooltip from '@component/tooltip/WizTooltip'
import WizImage from '@component/images/WizImage'
import img_sharp_calc from '@image/img_sharp_calc.png'
import img_sharp_calc_dark from '@image/img_sharp_calc_dark.png'
import {useRouter} from 'next/router'
import ThreeColorStack from '@feature/portfolio/components/ThreeColorStack/ThreeColorStack'
import useDark from '@hook/useDark'
import RiskLevelTag from '@component/tags/RiskLevelTag'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    total_value?: number
    daily_earn?: number
    daily_score?: number
    monthly_score?: number
    all_time_score?: number
    isMasked?: boolean
    analytics?: IAnalytics
    risk_level?: number
    isFetching?: boolean
}

const PortfolioBalanceHeader: React.FC<IProps> = ({
    className,
    total_value,
    daily_earn,
    daily_score,
    monthly_score,
    all_time_score,
    isMasked = false,
    analytics,
    risk_level,
    isFetching,
}) => {
    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const router = useRouter()
    const {isRenderDark} = useDark()
    return (
        <div className={`grid grid-cols-2 md:grid-cols-1 items-center ${className}`}>
            <div className={''}>
                <Text className={'text-ti3 text-gray02 dark:text-dark_gray02 font-bold'}>
                    {t('balanceHeader.currentBalance')}
                </Text>
                <div className={'flex items-center'}>
                    {isFetching ? (
                        <SkeletonBlock className={'h-[54px] w-[200px]'} sizeClassName={'h-[20px]'} />
                    ) : (
                        <TextHighlight
                            text={displayBalance(total_value, {
                                showPreSign: true,
                                isApproximately: isMasked,
                                isShowFullNumber: true,
                            })}
                        />
                    )}
                </div>
                <div className={`${isMasked === true ? 'hidden' : ''} mt-[5px] flex items-center`}>
                    {isFetching ? (
                        <SkeletonBlock className={'w-[87px] inline-block'} sizeClassName={'inline-block h-[15px]'} />
                    ) : (
                        <Span className={`text-ti2 ${getNumberColorClass(daily_earn)}`}>
                            {displayBalance(daily_earn, {showPreSign: true})} ({displayPercent(daily_score)})
                        </Span>
                    )}
                    <Span className={'text-body2 text-gray03 dark:text-dark_gray03 ml-[5px]'}>
                        {t('balanceHeader.last24hours')}
                    </Span>
                </div>
            </div>

            <div className={'flex justify-end gap-x-[40px] md:justify-between md:mt-[20px] md:gap-x-0'}>
                <div className={''}>
                    <Text
                        className={
                            'text-body_comm text-gray02 dark:text-dark_gray02 font-bold text-center md:text-ti3'
                        }>
                        {t('balanceHeader.stats.24hour.title')}
                    </Text>
                    {isFetching ? (
                        <SkeletonBlock className={'h-[30px] w-[60px]'} sizeClassName={'h-[20px]'} />
                    ) : (
                        <Text
                            className={`text-h3 text-center mt-[2px] md:mt-[3px] md:text-ti2 ${getNumberColorClass(
                                daily_score,
                            )}`}>
                            {displayPercent(daily_score)}
                        </Text>
                    )}
                </div>

                <div className={''}>
                    <Text
                        className={
                            'text-body_comm text-gray02 dark:text-dark_gray02 font-bold text-center md:text-ti3'
                        }>
                        {t('balanceHeader.stats.30days.title')}
                    </Text>
                    {isFetching ? (
                        <SkeletonBlock className={'h-[30px] w-[60px]'} sizeClassName={'h-[20px]'} />
                    ) : (
                        <Text
                            className={`text-h3 text-center mt-[2px] md:mt-[3px] md:text-ti2 ${getNumberColorClass(
                                monthly_score,
                            )}`}>
                            {displayPercent(monthly_score)}
                        </Text>
                    )}
                </div>

                <div className={''}>
                    <Text
                        className={
                            'text-body_comm text-gray02 dark:text-dark_gray02 font-bold text-center md:text-ti3'
                        }>
                        {t('balanceHeader.stats.allTime.title')}
                    </Text>
                    {isFetching ? (
                        <SkeletonBlock className={'h-[30px] w-[60px]'} sizeClassName={'h-[20px]'} />
                    ) : (
                        <Text
                            className={`text-h3 text-center mt-[2px] md:mt-[3px] md:text-ti2 ${getNumberColorClass(
                                all_time_score,
                            )}`}>
                            {displayPercent(all_time_score)}
                        </Text>
                    )}
                </div>

                {risk_level !== undefined && (
                    <div className={''}>
                        <div className={'flex gap-x-[5px] justify-center'}>
                            <Text
                                className={
                                    'text-body_comm text-gray02 dark:text-dark_gray02 font-bold text-center md:text-ti3'
                                }>
                                {t('balanceHeader.stats.riskLevel.title')}
                            </Text>

                            <WizTooltip
                                type={'portfolio_risk_level_tooltip'}
                                placement={'bottom-end'}
                                title={t('balanceHeader.stats.riskLevel.tooltip.title')}
                                panelClassName={'max-w-[380px] sm:max-w-[340px]'}
                                render={
                                    isRenderDark ? (
                                        <IconInfoDark
                                            className={
                                                'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01 relative top-[-3px]'
                                            }
                                        />
                                    ) : (
                                        <Info className={'w-[20px] fill-white hover:fill-gray08 relative top-[-3px]'} />
                                    )
                                }>
                                <TagText className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                                    {t('balanceHeader.stats.riskLevel.tooltip.desc')}
                                </TagText>
                                <div className={'pt-[10px] flex items-center justify-between'}>
                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.low')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_low={true}
                                            color_low={'bg-primary dark:bg-dark_primary'}
                                        />
                                    </div>
                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.moderate')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_fair={true}
                                            color_fair={'bg-yellow dark:bg-dark_yellow'}
                                        />
                                    </div>

                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.high')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_fair={true}
                                            charge_high={true}
                                            color_fair={'bg-yellow dark:bg-dark_yellow'}
                                            color_high={'bg-red dark:bg-dark_red'}
                                        />
                                    </div>
                                </div>
                            </WizTooltip>
                        </div>
                        <div className={'flex items-center gap-x-[5px] justify-center'}>
                            {isFetching ? (
                                <SkeletonBlock className={'h-[30px] w-[60px]'} sizeClassName={'h-[20px]'} />
                            ) : (
                                <Text className={`text-h4 text-center md:text-ti2 text-gray01 dark:text-dark_gray01`}>
                                    {risk_level === 0
                                        ? t('balanceHeader.stats.riskLevel.accuracy.low')
                                        : risk_level === 1
                                        ? t('balanceHeader.stats.riskLevel.accuracy.moderate')
                                        : t('balanceHeader.stats.riskLevel.accuracy.high')}
                                </Text>
                            )}
                            <WizTooltip
                                type={'portfolio_risk_level_tooltip'}
                                placement={'bottom-end'}
                                title={t('balanceHeader.stats.riskLevel.tooltip.title')}
                                panelClassName={'max-w-[380px] sm:max-w-[340px]'}
                                render={<RiskLevelTag riskLevel={risk_level} />}>
                                <TagText className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                                    {t('balanceHeader.stats.riskLevel.tooltip.desc')}
                                </TagText>
                                <div className={'pt-[10px] flex items-center justify-between'}>
                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.low')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_low={true}
                                            color_low={'bg-primary dark:bg-dark_primary'}
                                        />
                                    </div>
                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.moderate')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_fair={true}
                                            color_fair={'bg-yellow dark:bg-dark_yellow'}
                                        />
                                    </div>

                                    <div className={'flex items-center'}>
                                        <Text className={'text-body_comm text-gray02 dark:text-dark_gray02'}>
                                            {t('balanceHeader.stats.riskLevel.accuracy.high')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[10px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.riskLevel.accuracy')}
                                        </Text>
                                        <ThreeColorStack
                                            charge_fair={true}
                                            charge_high={true}
                                            color_fair={'bg-yellow dark:bg-dark_yellow'}
                                            color_high={'bg-red dark:bg-dark_red'}
                                        />
                                    </div>
                                </div>
                            </WizTooltip>
                        </div>
                    </div>
                )}

                {router?.route?.includes('user') && (
                    <div className={''}>
                        <div className={'flex gap-x-[5px] justify-center'}>
                            <Text
                                className={
                                    'text-body_comm text-gray02 dark:text-dark_gray02 font-bold text-center md:text-ti3'
                                }>
                                {t('balanceHeader.stats.sharpeRatio.title')}
                            </Text>

                            <WizTooltip
                                type={'portfolio_sharpe_ratio'}
                                plusX={-10}
                                placement={'bottom'}
                                title={t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.title')}
                                render={
                                    isRenderDark ? (
                                        <IconInfoDark
                                            className={
                                                'w-[20px] fill-bg_dark_white03 hover:fill-bg_dark_white01 relative top-[-3px]'
                                            }
                                        />
                                    ) : (
                                        <Info className={'w-[20px] fill-white hover:fill-gray08 relative top-[-3px]'} />
                                    )
                                }>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                                    {t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.text')}
                                </Text>
                                <WizImage
                                    src={isRenderDark ? img_sharp_calc_dark : img_sharp_calc}
                                    containerClassName={'max-w-[170px] mx-auto py-[20px]'}
                                />
                                <TagText className={'text-body3 text-gray02 dark:text-dark_gray02 '}>
                                    {t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.text2')}
                                </TagText>

                                <Text className={'pt-[30px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {t('portfolio.dashboard.profitGraph.selector.type.accuracy.tooltip.title')}
                                </Text>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 pt-[10px]'}>
                                    {t('portfolio.dashboard.profitGraph.selector.type.accuracy.tooltip.text')}
                                </Text>
                                <div className={'pt-[10px] flex items-center justify-between'}>
                                    <div className={'flex items-center px-[5px]'}>
                                        <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                            {t('portfolio.dashboard.profitGraph.selector.type.accuracy.row')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                        </Text>
                                        <ThreeColorStack charge_low={true} />
                                    </div>
                                    <div className={'flex items-center px-[5px]'}>
                                        <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                            {t('portfolio.dashboard.profitGraph.selector.type.accuracy.fair')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                        </Text>
                                        <ThreeColorStack charge_fair={true} />
                                    </div>

                                    <div className={'flex items-center px-[5px]'}>
                                        <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                            {t('portfolio.dashboard.profitGraph.selector.type.accuracy.high')}
                                        </Text>
                                        <Text
                                            className={
                                                'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                            }>
                                            {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                        </Text>
                                        <ThreeColorStack charge_fair={true} charge_high={true} />
                                    </div>
                                </div>
                            </WizTooltip>
                        </div>
                        <div className={'flex items-center gap-x-[5px] justify-center mt-[-3px] md:mt-[-1px]'}>
                            {isFetching ? (
                                <SkeletonBlock className={'h-[30px] w-[54px]'} sizeClassName={'h-[20px]'} />
                            ) : (
                                <Text className={`text-h3 text-center md:text-ti2 text-gray01 dark:text-dark_gray01`}>
                                    {analytics?.sharpe_ratio
                                        ? analytics?.sharpe_ratio?.sharpe_ratio
                                            ? displayNumber(analytics?.sharpe_ratio?.sharpe_ratio, 2)
                                            : '0.00'
                                        : '-'}
                                </Text>
                            )}
                            {analytics?.sharpe_ratio && (
                                <WizTooltip
                                    type={'portfolio_sharpe_ratio'}
                                    plusX={-10}
                                    placement={'bottom'}
                                    title={t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.title')}
                                    render={
                                        <div
                                            className={
                                                'bg-white dark:bg-bg_dark_white02 px-[7px] py-[3px] flex gap-x-[4px] items-center rounded-[3px]'
                                            }>
                                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                                {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                            </Text>
                                            <ThreeColorStack
                                                charge_low={analytics?.sharpe_ratio?.accuracy === 0}
                                                charge_fair={analytics?.sharpe_ratio?.accuracy > 0}
                                                charge_high={analytics?.sharpe_ratio?.accuracy > 1}
                                            />
                                        </div>
                                    }>
                                    <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[5px]'}>
                                        {t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.text')}
                                    </Text>
                                    <WizImage
                                        src={isRenderDark ? img_sharp_calc_dark : img_sharp_calc}
                                        containerClassName={'max-w-[170px] mx-auto py-[20px]'}
                                    />
                                    <TagText className={'text-body3 text-gray02 dark:text-dark_gray02 '}>
                                        {t('portfolio.dashboard.profitGraph.selector.type.sharpeRatio.tooltip.text2')}
                                    </TagText>

                                    <Text className={'pt-[30px] text-ti2 text-gray01 dark:text-dark_gray01'}>
                                        {t('portfolio.dashboard.profitGraph.selector.type.accuracy.tooltip.title')}
                                    </Text>
                                    <Text className={'text-body3 text-gray02 dark:text-dark_gray02 pt-[10px]'}>
                                        {t('portfolio.dashboard.profitGraph.selector.type.accuracy.tooltip.text')}
                                    </Text>
                                    <div className={'pt-[10px] flex items-center justify-between'}>
                                        <div className={'flex items-center px-[5px]'}>
                                            <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                                {t('portfolio.dashboard.profitGraph.selector.type.accuracy.row')}
                                            </Text>
                                            <Text
                                                className={
                                                    'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                                }>
                                                {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                            </Text>
                                            <ThreeColorStack charge_low={true} />
                                        </div>
                                        <div className={'flex items-center px-[5px]'}>
                                            <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                                {t('portfolio.dashboard.profitGraph.selector.type.accuracy.fair')}
                                            </Text>
                                            <Text
                                                className={
                                                    'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                                }>
                                                {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                            </Text>
                                            <ThreeColorStack charge_fair={true} />
                                        </div>

                                        <div className={'flex items-center px-[5px]'}>
                                            <Text className={'text-[13px] font-bold text-gray02 dark:text-dark_gray02'}>
                                                {t('portfolio.dashboard.profitGraph.selector.type.accuracy.high')}
                                            </Text>
                                            <Text
                                                className={
                                                    'text-body3 text-gray02 dark:text-dark_gray02 pl-[7px] pr-[4px]'
                                                }>
                                                {t('balanceHeader.stats.sharpeRatio.accuracy')}
                                            </Text>
                                            <ThreeColorStack charge_fair={true} charge_high={true} />
                                        </div>
                                    </div>
                                </WizTooltip>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PortfolioBalanceHeader
