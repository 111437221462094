import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'

export const sendEmailVerificationCode = async (email: string): Promise<ApiRes<IEmailVerificationResponse>> => {
    return await apiPost('confirm/email', {email: email})
}

export const sendPhoneVerificationCode = async (phone: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('confirm/phone', {phone: phone})
}

export const confirmEmailVerificationCode = async (
    email: string,
    email_code: string,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('check', {email: email, email_code: email_code})
}

export const confirmPhoneVerificationCode = async (
    phone: string,
    phone_code: string,
): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('check', {phone: phone, phone_code: phone_code})
}

/**
 deprecated
 */
export const registerEmail = async (
    email: string,
    email_code: string,
    nickname: string,
    password: string,
    phone?: string,
    phone_code?: string,
    referral?: string,
): Promise<ApiRes<RegisterUserResponse>> => {
    return await apiPost('register', {
        email: email,
        email_code: email_code,
        password: password,
        name: nickname,
        phone: phone,
        phone_code: phone_code,
        referral: referral,
    })
}

export interface IEmailVerificationResponse {
    success: boolean
    user_exists: boolean
}

export interface INicknameVerificationResponse {
    success: boolean
    user_exists: boolean
}

export interface IConfirmResponse {
    success: boolean
    status?: number
}

export interface RegisterUserResponse {
    success: boolean
    user_id: string
    access_token: string
}
