import React, {useImperativeHandle, useMemo, useReducer, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import useSnackbar from '@hook/useSnackbar'
import Text from '@component/Text/Text'
import InputTimer from '@component/timer/InputTimer'
import {emailValidation} from '@util/validation'
import {confirmEmailVerificationCode, sendEmailVerificationCode} from '@api/auths/register/Register'
import WizContentModal from '@component/modal/WizContentModal'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {createLog} from '@util/logs'
import {apiEditEmail} from '@api/user/account/editEmail'

interface IProps {
    callback?: (email?: string) => void
}

const initialState = {
    /* email */
    email: '',
    emailErrorMessage: '',
    emailSent: false,

    /* email code */
    emailCode: '',
    emailCodeErrorMessage: '',
    emailVerified: false,
}

const ChangeEmailModal = ({callback}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {data: me, refetch: refreshMe} = useQueryFetchMe(undefined, false)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [isCallback, setIsCallback] = useState(true)
    const {showSnackbar, showErrorSnackbar} = useSnackbar()

    const {email, emailErrorMessage, emailSent, emailCode, emailCodeErrorMessage, emailVerified} = state

    const emailTimerRef = useRef<any>()

    function reducer(state, action) {
        switch (action.type) {
            case 'field_update':
                if (action.field == 'email') {
                    return {
                        ...state,
                        email: action.value,
                        emailSent: false,
                        emailVerified: false,
                    }
                }

                return {...state, [action.field]: action.value}
            case 'send_email_success':
                return {
                    ...state,
                    emailSent: true,
                    emailVerified: false,
                    emailErrorMessage: '',
                    emailCode: '',
                    emailCodeErrorMessage: '',
                }
            case 'send_email_fail':
                switch (action.errorType) {
                    case 'duplicate_email':
                        return {
                            ...state,
                            emailSent: false,
                            emailVerified: false,
                            emailErrorMessage: t('modal.changeEmail.email.warning.duplicate'),
                        }
                    default:
                        return {
                            ...state,
                            emailSent: false,
                            emailVerified: false,
                        }
                }

            case 'confirm_email_success':
                return {...state, emailVerified: true, emailErrorMessage: ''}
            case 'confirm_email_fail':
                return {
                    ...state,
                    emailVerified: false,
                    emailCodeErrorMessage: t('modal.changeEmail.email.input.warning.wrong'),
                }
        }
    }

    useImperativeHandle(ref, () => ({
        show: (isConfirmEmail?: boolean) => {
            setIsCallback(isConfirmEmail)
            modalRef.current.show()
        },
    }))
    const isValidateEmailForm = useMemo(() => emailValidation(email), [email])

    const getEmailInputState = useMemo(() => {
        if (emailVerified) return InputState.CONFIRM
        else if (emailErrorMessage) {
            return InputState.ERROR
        } else return InputState.DEFAULT
    }, [emailErrorMessage, emailVerified])

    const onClickSendEmail = async () => {
        if (!isValidateEmailForm) {
            showErrorSnackbar(t('modal.changeEmail.email.warning.wrong.type'))
            return
        }

        const {data, error, status} = await sendEmailVerificationCode(email)
        if (data) {
            if (data?.success && data?.user_exists === false) {
                dispatch({type: 'send_email_success'})
                createLog('event', 'account_verify_email_code', {email})
                if (emailTimerRef.current) {
                    emailTimerRef.current.startTimer()
                }
            } else {
                createLog('error', 'account_verify_email_code_failed_duplicated', {email})
                dispatch({type: 'send_email_fail', errorType: 'duplicate_email'})
            }
        } else {
            createLog('error', 'account_verify_email_code_failed', {email})
            showErrorSnackbar(error)
        }
    }

    const onClickConfirmEmail = async () => {
        const {data, error, status} = await confirmEmailVerificationCode(email, emailCode)
        if (data) {
            if (data?.success) {
                dispatch({type: 'confirm_email_success'})
                createLog('event', 'account_confirm_email_code', {email})
            } else {
                createLog('error', 'account_confirm_email_code_failed', {email})
                dispatch({type: 'confirm_email_fail'})
            }
        } else {
            createLog('error', 'account_confirm_email_code_failed', {email})
            showErrorSnackbar(error)
        }
    }

    const onClickEditEmail = async () => {
        const {data, error, status} = await apiEditEmail(email, emailCode)

        if (data?.success) {
            showSnackbar(t('modal.changeEmail.result.success'))
            createLog('event', 'account_change_email', {email})
            await refreshMe().then(res => {
                modalRef.current.close()
                isCallback === false && callback && callback(res?.data?.email)
            })
        } else {
            createLog('error', 'account_change_email_failed', {email})
            showErrorSnackbar(error)
        }
    }

    return (
        <WizContentModal
            title={!isCallback ? t('modal.changeEmail.setPassword.title') : t('modal.changeEmail.title')}
            size={'400'}
            ref={modalRef}>
            <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                {email && emailSent
                    ? t('modal.changeEmail.setCode.desc')
                    : !isCallback
                    ? t('modal.changeEmail.setPassword.desc')
                    : t('modal.changeEmail.desc')}
            </Text>

            <WizInputText
                className={'mt-[10px]'}
                text={email}
                state={getEmailInputState}
                onChanged={text => dispatch({type: 'field_update', field: 'email', value: text})}
                errorDescription={emailErrorMessage}
                placeholder={t('modal.changeEmail.email.input.placeholder')}
                renderRightArea={
                    !emailVerified && (
                        <WizButton
                            text={t('modal.changeEmail.email.confirm.text')}
                            buttonType={'border'}
                            size={'medium'}
                            onClick={onClickSendEmail}
                        />
                    )
                }
            />
            {email && emailSent && !emailVerified && (
                <WizInputText
                    className={'mt-[5px]'}
                    text={emailCode}
                    onChanged={text => dispatch({type: 'field_update', field: 'emailCode', value: text})}
                    placeholder={t('modal.changeEmail.code.input.placeholder')}
                    errorDescription={emailCodeErrorMessage}
                    state={emailCodeErrorMessage ? InputState.ERROR : undefined}
                    renderInputRightArea={<InputTimer initialTime={180} ref={emailTimerRef} />}
                    renderRightArea={
                        <WizButton
                            text={t('modal.changeEmail.code.confirm.text')}
                            buttonType={'border'}
                            size={'medium'}
                            onClick={onClickConfirmEmail}
                        />
                    }
                />
            )}

            <div className={'grid grid-cols-2 gap-[5px] mt-[20px]'}>
                <WizButton
                    text={t('modal.changeEmail.submit.cancel')}
                    buttonType={'border'}
                    onClick={() => modalRef.current.close()}
                />
                <WizButton
                    text={t('modal.changeEmail.submit.save')}
                    buttonType={'primary'}
                    disabled={!emailVerified}
                    onClick={onClickEditEmail}
                />
            </div>
        </WizContentModal>
    )
}

export default React.forwardRef(ChangeEmailModal)
