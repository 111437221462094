import React, {useMemo} from 'react'
import {ILeaderRegisterKey} from '@api/copy/leader/getJoinableKey'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import useFormatNumber from '@hook/useFormatNumber'
import {useTranslation} from 'next-i18next'
import {IRegisterLeaderSelectKeyRes} from '@feature/copy/components/CreateLeaderModal/LeaderSelectKeyContainer/LeaderSelectKeyContainer'
import {getNumberColorClass} from '@util/etc'
import useFormatDate from '@hook/useFormatDate'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import IconErrorMessage from '@svg/common/ic_error_message.svg'
import KeyValidTag from '@component/tags/KeyValidTag'

interface IProps extends ILeaderRegisterKey {
    className?: string
    isSelected?: boolean
    onChanged?: (key: IRegisterLeaderSelectKeyRes) => void
}

const SelectLeaderKeyItem: React.FC<IProps> = props => {
    const {t} = useTranslation()
    const {
        className,
        isSelected = false,
        exchange,
        name,
        id,
        lead_state,
        access_key,
        onChanged,
        blocked_until,
        current_balance,
        all_time_profit,
    } = props

    const {displayDateFromText} = useFormatDate()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {getExchange} = useQueryGetExchanges()

    const isValidKey = useMemo(() => {
        return lead_state.includes(1)
    }, [lead_state])

    const getErrorMessage = useMemo(() => {
        if (lead_state.includes(2)) {
            return t('modal.createLeader.step2.item.error.alreadyLeader')
        } else if (lead_state.includes(3)) {
            return t('modal.createLeader.step2.item.error.usingBot')
        } else if (lead_state.includes(4)) {
            return t('modal.createLeader.step2.item.error.notSupportExchange')
        } else if (lead_state.includes(5)) {
            return t('modal.createLeader.step2.item.error.newApiBefore7Days')
        } else if (lead_state.includes(6)) {
            return t('modal.createLeader.step2.item.error.insufficientBalance', {
                exchange: getExchange(exchange)?.display_name,
            })
        } else if (lead_state.includes(7)) {
            return t('modal.createLeader.step2.item.error.notYet30Days', {
                date: displayDateFromText(blocked_until, t('dateFormat.date')),
            })
        } else if (lead_state.includes(8)) {
            return t('modal.createLeader.step2.item.error.isUnifiedAccount')
        } else {
            return undefined
        }
    }, [t, blocked_until, lead_state, exchange])

    const renderExchangeMarketText = useMemo(() => {
        switch (exchange) {
            case 'binance':
                return t('modal.createLeader.step2.exchange.market.binance')
            case 'bithumb':
                return t('modal.createLeader.step2.exchange.market.bithumb')
            case 'bitmex':
                return t('modal.createLeader.step2.exchange.market.bitmex')
            case 'upbit':
                return t('modal.createLeader.step2.exchange.market.upbit')
        }
    }, [exchange, t])

    return (
        <div
            className={`p-[20px] border shadow-normal rounded-[5px] ${
                isValidKey
                    ? isSelected
                        ? 'bg-brown_tint01 dark:bg-dark_brown_tint01 cursor-pointer border-brown dark:border-dark_brown'
                        : 'bg-white dark:bg-bg_dark_white01 hover:bg-brown_tint01 dark:hover:bg-dark_brown_tint01 cursor-pointer border-gray06 hover:border-brown dark:border-bg_dark_white02 dark:hover:border-dark_brown'
                    : 'bg-gray09 dark:bg-bg_dark_white02 border-gray06 dark:border-dark_gray06'
            } ${className}`}
            onClick={() => {
                onChanged({leaderKey: {...props}, verified: isValidKey, errorMessage: getErrorMessage})
            }}>
            <div className={`grid grid-cols-4 sm:grid-cols-3 items-center gap-y-[10px]`}>
                <div className={'sm:col-span-3'}>
                    <div className={'flex items-center space-x-[10px] col-span-2'}>
                        <KeyValidTag status={isValidKey ? 'valid' : 'invalid'} />
                        <Text
                            className={`text-body2 ${
                                isValidKey ? 'text-gray01 dark:text-dark_gray01' : 'text-gray04 dark:text-dark_gray04'
                            } break-all`}>
                            {name || id}
                        </Text>
                    </div>
                </div>
                <div className={'flex items-center space-x-[5px] justify-end sm:justify-start'}>
                    <WizImage src={getExchange(exchange)?.icon} width={12} height={12} />
                    <Text
                        className={`text-body2 text-gray01 ${
                            isValidKey ? 'text-gray01 dark:text-dark_gray01' : 'text-gray04 dark:text-dark_gray04'
                        }`}>
                        {getExchange(exchange)?.display_name}
                    </Text>
                </div>
                <div>
                    <Text
                        className={`text-ti4 ${
                            isValidKey ? 'text-gray03 dark:text-dark_gray03' : 'text-gray04 dark:text-dark_gray04'
                        } text-right`}>
                        {t('modal.createLeader.step2.item.profit.title')}
                    </Text>
                    <Text
                        className={`text-ti2 ${
                            isValidKey ? `${getNumberColorClass(all_time_profit)}` : 'text-gray04 dark:text-dark_gray04'
                        } text-right`}>
                        {displayPercent(all_time_profit)}
                    </Text>
                </div>
                <div>
                    <Text
                        className={`text-ti4 ${
                            isValidKey ? 'text-gray03 dark:text-dark_gray03' : 'text-gray04 dark:text-dark_gray04'
                        } text-right`}>
                        {t('modal.createLeader.step2.item.balance.title')}
                    </Text>
                    <Text
                        className={`text-ti2 text-right ${
                            isValidKey ? 'text-gray01 dark:text-dark_gray01' : 'text-gray04 dark:text-dark_gray04'
                        }`}>
                        {displayBalance(current_balance, {showPreSign: true})}
                    </Text>
                </div>
            </div>
            {getErrorMessage && (
                <div className={'flex items-center space-x-[10px] justify-center mt-[10px] pt-[10px]'}>
                    <IconErrorMessage className={'w-[16px] fill-red_shade dark:fill-dark_red flex-none'} />
                    <Text className={'text-body2 text-red_shade dark:text-dark_red_shade'}>{getErrorMessage}</Text>
                </div>
            )}
            {isSelected && !getErrorMessage && (
                <Text className={'text-gray02 dark:text-dark_gray02 text-body2 text-center pt-[10px]'}>
                    {renderExchangeMarketText}
                </Text>
            )}
        </div>
    )
}

export default SelectLeaderKeyItem
