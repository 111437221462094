import SideBarContainer from '@feature/portfolio/components/SideBar/SideBarContainer'
import MobileSideBarContainer from '@feature/portfolio/components/MobileSideBar/MobileSideBarContainer'
import React from 'react'

import PortfolioNavigation from '@feature/portfolio/components/PortfoiloNavigation/PortfolioNavigation'

interface IProps {
    id: string
    login_id: string
    children?: React.ReactNode
    bgContainerClassName?: string
    containerClassName?: string
    showNavigation?: boolean
    isMe?: boolean
}

const PortfolioLayout = ({
    login_id,
    id,
    children,
    bgContainerClassName,
    containerClassName,
    showNavigation,
    isMe,
}: IProps) => {
    return (
        <div className={`h-full ${bgContainerClassName} transition duration-[500ms] overflow-hidden`}>
            <MobileSideBarContainer id={id} isMe={isMe} />
            <div
                className={`layout-desktop px-[20px] pt-[20px] pb-[90px] sm:pb-[60px] sm:pt-[10px] sm:px-[0px] ${containerClassName} `}>
                <div className={'grid grid-cols-[244px_auto] w-full gap-[20px] lg:grid-cols-1'}>
                    <div className={'lg:hidden px-[20px]'}>
                        <SideBarContainer id={id} login_id={login_id} isMe={isMe} />
                    </div>
                    <div className={'lg:col-span-1'}>
                        {showNavigation && <PortfolioNavigation id={id} />}
                        <div className={'sm:px-[10px]'}>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioLayout
