import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import {useLoginStore} from '@store/LoginStore'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import {IpStatus, ProIpStatus} from '@type/profile'
import useFormatDate from '@hook/useFormatDate'
import useQueryGetCopierMineBot from '@hook/query/portfolio/copyfolio/useQueryGetCopierMineBot'
import {TIME_DAY} from '@constant/time'
import TagText from '@component/Text/TagText'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {getLinkUrl, URL_ACCOUNT_API, URL_ACCOUNT_INVOICE, URL_ACCOUNT_PRO, URL_ACCOUNT_SETTING} from '@constant/url'
import Text from '@component/Text/Text'
import {createLog} from '@util/logs'
import ExchangeTag from '@component/tags/ExchangeTag'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import IconNextArrow from '@svg/common/ic_next.svg'

export type ImportantAlertsType =
    | 'invoice_unpaid' // 인보이스 발생한 경우
    | 'invoice_incoming' // 예정된 인보이스 존재하는 경우
    | 'api_inactive' // API가 끊긴 경우
    | 'api_expire' // 3개월 이내에 거래소 연결이 만료되는 경우(Upbit)
    | 'api_permission' // API 키에 문제가 있는 경우
    | 'api_binance_noIP' //IP 등록이 안되어 있는 경우(Binance)
    | 'api_not_updated_pro' // PRO 서비스를 이용하고 있지만 API에 PRO IP가 등록되지 않은 경우
    | 'pro_expire' // 웹에서 구독한 유저는 PRO 만료 알림
    | 'pro_expire_copyTrade' // PRO 만료가 다가오며, 카피 트레이딩 중단 예정 (Bybit)
    | 'pro_expire_end' // PRO 만료하여 카피 트레이딩 중단 (Bybit)
    | 'email_connect' // 이메일 등록이 필요한 경우 (kakaoTalk)

export interface IImportantAlerts {
    id: string
    type: ImportantAlertsType
    accountBoxView: ReactNode
    modalBoxView: ReactNode
    actionText: string
    action: () => void
}

const REMOVE_ALERT_LIST_KEY = 'remove_important_alert'

// Context 안에서 참조할 값들
interface ImportantAlertsContextValue {
    alertList: IImportantAlerts[]
    alertLength: number
    isLoading: boolean
}

// 실제 Context 생성
const ImportantAlertsContext = createContext<ImportantAlertsContextValue>({} as ImportantAlertsContextValue)

// Provider에서 children을 받을 때 타입
interface ImportantAlertsProviderProps {
    children: ReactNode
}

export function ImportantAlertsProvider({children}: ImportantAlertsProviderProps) {
    const loginId = useLoginStore(state => state.id)

    const {t} = useTranslation()
    const router = useRouter()
    const {getExchange} = useQueryGetExchanges()
    const {fromTodayToDate} = useFormatDate()
    const [alertList, setAlertList] = useState<IImportantAlerts[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [ignoredNotification = [], setIgnoredNotification] = useState<string[]>([])

    useEffect(() => {
        const stored = localStorage?.getItem(REMOVE_ALERT_LIST_KEY)
        if (stored) {
            try {
                const arr = JSON.parse(stored)
                setIgnoredNotification(Array.isArray(arr) ? arr : [])
            } catch {
                // 파싱 에러 시 무시
            }
        }
    }, [])

    const isLogin = loginId && loginId?.length > 0
    const {data: me, refetch: refetchMe} = useQueryFetchMe(undefined, false)
    const {data: pendingInvoices, refresh: refetchPendingInvoices} = useQueryGetPendingInvoices()
    const {data: copyBot} = useQueryGetCopierMineBot()

    // ------------------ important alerts 조건 ------------------

    /*Start 인보이스 발생한 경우*/
    const unpaidInvoice = useMemo(() => {
        if (!pendingInvoices) return []
        return pendingInvoices
    }, [pendingInvoices])
    /*End 인보이스 발생한 경우*/

    /*Start 예정된 인보이스 존재하는 경우*/
    const incomingInvoice = useMemo(() => {
        return copyBot?.bots
            ?.filter(bot => {
                const now = Math.trunc(new Date().getTime() / 1000)
                const nextPaymentDate = bot?.next_payment_date ? new Date(bot.next_payment_date).getTime() / 1000 : 0
                const remainingTimeSecond = nextPaymentDate - now
                return (
                    bot.status === 'running' &&
                    (bot?.next_payment_amount ?? 0) > 0 &&
                    remainingTimeSecond > 0 &&
                    remainingTimeSecond <= 2 * TIME_DAY
                )
            })
            .sort((a, b) => new Date(a.next_payment_date ?? 0).getTime() - new Date(b.next_payment_date ?? 0).getTime())
    }, [copyBot])
    /*End 예정된 인보이스 존재하는 경우*/

    /*Start API가 끊긴 경우*/
    const inActiveExchange = useMemo(() => {
        const inActiveKeys = (me?.keys || []).filter(k => !k.is_active)

        if (inActiveKeys.length === 0) return undefined

        return inActiveKeys
    }, [me?.keys])
    /*End API가 끊긴 경우*/

    /*Start 3개월 이내에 거래소 연결이 만료되는 경우(Upbit)*/
    const expireSoonExchange = useMemo(() => {
        const getUpbitExpireIn3Months = expired_at => {
            const f = me?.keys?.filter(() => {
                if (!expired_at) return false
                return Date.now() / 1000 > expired_at - 90 * 86400
            })
            return f?.length
        }
        const expireSoonKeys = (me?.keys || []).filter(
            k => k.exchange === 'upbit' && k.is_active && getUpbitExpireIn3Months({expire_at: k?.expire_at ?? 0}),
        )
        if (expireSoonKeys.length === 0) return undefined
        return expireSoonKeys
    }, [me?.keys])
    /*End 3개월 이내에 거래소 연결이 만료되는 경우(Upbit)*/

    /*Start API 키에 문제가 있는 경우(Permission)*/
    const missingPermissionExchange = useMemo(() => {
        const missingKeys = (me?.keys || []).filter(
            k => k.is_active && k.missing_permissions && k.missing_permissions.length > 0,
        )

        if (missingKeys.length === 0) return undefined

        return missingKeys
    }, [me?.keys])
    /*End API 키에 문제가 있는 경우(Permission)*/

    /*Start IP 등록이 안되어 있는 경우(Binance)*/
    const noIpBinanceExchange = useMemo(() => {
        const noIpKeys = (me?.keys || []).filter(
            k =>
                k.is_active &&
                k.exchange === 'binance' &&
                (!k.pro_ip_status || k.pro_ip_status === ProIpStatus.NotSet) &&
                k.ip_status === IpStatus.NotSet,
        )

        if (noIpKeys.length === 0) return undefined

        return noIpKeys
    }, [me?.keys])
    /*Start IP 등록이 안되어 있는 경우(Binance)*/

    /*Start PRO 서비스를 이용하고 있지만 API에 PRO IP가 등록되지 않은 경우*/
    const notConnectedExchanges = useMemo(() => {
        const proExchanges = me?.keys?.filter(e => e.is_active && e.pro_ip_status !== undefined)
        return proExchanges?.filter(e => e.pro_ip_status === ProIpStatus.NotSet)
    }, [me?.keys])
    /*End PRO 서비스를 이용하고 있지만 API에 PRO IP가 등록되지 않은 경우*/

    // Start 웹에서 구독한 유저는 PRO 만료 알림
    // Start PRO 만료가 다가오며, 카피 트레이딩 중단 예정 (Bybit)
    const proExpireAtIncoming = useMemo(() => {
        if (me?.is_pro && fromTodayToDate(me?.pro_until)?.day <= 3) {
            return true
        }
        return false
    }, [me?.is_pro, me?.pro_until])
    // End 웹에서 구독한 유저는 PRO 만료 알림
    // End PRO 만료가 다가오며, 카피 트레이딩 중단 예정 (Bybit)

    // Start PRO 만료하여 카피 트레이딩 중단 (Bybit)
    const proExpiredCopyTrading = useMemo(() => {
        if (new Date(me?.pro_until)?.getTime() < Date.now()) {
            const f = copyBot?.bots?.filter(item => item?.exchange === 'bybit' && item?.status === 'running')
            return f?.length > 0
        }
        return false
    }, [copyBot?.bots, me?.pro_until])
    // End PRO 만료하여 카피 트레이딩 중단 (Bybit)

    // Start 이메일 등록이 필요한 경우 (kakaoTalk)
    const checkHasEmail = useMemo(() => {
        if (me?.id && (!me?.email_confirmed || !me?.email)) {
            return true
        }
        return false
    }, [me?.id, me?.email, me?.email_confirmed])
    // End 이메일 등록이 필요한 경우 (kakaoTalk)

    // 중요 알림에서 삭제 Function
    const removeAlert = useCallback(async (id: string, type: ImportantAlertsType) => {
        const l = localStorage?.getItem(REMOVE_ALERT_LIST_KEY)
        if (l) {
            const r: string[] = JSON.parse(l)

            if (!r?.includes(`${type}_${id}`)) {
                r?.push(`${type}_${id}`)
                localStorage.setItem(REMOVE_ALERT_LIST_KEY, JSON.stringify(r))
                setIgnoredNotification(r)
                createLog('event', 'account_important_alert_remove', {type: type})
            }
        } else {
            localStorage.setItem(REMOVE_ALERT_LIST_KEY, JSON.stringify([`${type}_${id}`]))
            setIgnoredNotification([`${type}_${id}`])
            createLog('event', 'account_important_alert_remove', {type: type})
        }
    }, [])

    useEffect(() => {
        if (!isLogin) return
        setIsLoading(true)
        const list: IImportantAlerts[] = []

        // unpaid invoice
        if (unpaidInvoice && unpaidInvoice?.length > 0) {
            unpaidInvoice?.forEach(invoice => {
                list?.push({
                    id: `account_alert_invoice_unpaid_${invoice?.id}`,
                    type: 'invoice_unpaid',
                    modalBoxView: (
                        <div>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.accountAlert.invoice.unpaid')}
                            </Text>
                            <div className={'flex items-center gap-x-[5px] mt-[3px]'}>
                                <ExchangeTag
                                    removeText={true}
                                    className={'flex-none w-[14px]'}
                                    exchange={me?.keys?.find(item => item?.key_id === invoice?.key_id)?.exchange}
                                />
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    {invoice?.user_nickname ??
                                        getExchange(me?.keys?.find(item => item?.key_id === invoice?.key_id)?.exchange)
                                            ?.display_name}
                                </Text>
                                <IconNextArrow className={'w-[14px] fill-gray03 dark:fill-dark_gray03 flex-none'} />
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    {invoice?.leader_name}
                                </Text>
                            </div>
                        </div>
                    ),
                    accountBoxView: (
                        <div>
                            <TagText
                                className={'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'}>
                                {t('accountAlert.invoice.unpaid')}
                            </TagText>
                        </div>
                    ),
                    actionText: t('accountAlert.button.text.invoices.unpaid'),
                    action: () => {
                        createLog('event', 'account_important_alert_click', {type: 'invoice_unpaid'})
                        router?.push(getLinkUrl(URL_ACCOUNT_INVOICE))
                    },
                })
            })
        }

        // incoming invoice
        if (incomingInvoice && incomingInvoice?.length > 0) {
            incomingInvoice?.forEach(bot => {
                list?.push({
                    id: `account_alert_invoice_incoming_${bot?.id}`,
                    type: 'invoice_incoming',
                    accountBoxView: (
                        <div>
                            <TagText
                                className={'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'}>
                                {t('accountAlert.invoice.incoming')}
                            </TagText>
                            <Text className={'text-ti2 sm:text-ti3 text-gray01 dark:text-dark_gray01'}>
                                {fromTodayToDate(bot?.next_payment_date)?.day > 0
                                    ? t('accountAlert.invoice.incoming.day', {
                                          count: fromTodayToDate(bot?.next_payment_date)?.day,
                                      })
                                    : t('accountAlert.invoice.incoming.hour', {
                                          hour: fromTodayToDate(bot?.next_payment_date)?.hour,
                                          min: fromTodayToDate(bot?.next_payment_date)?.minute,
                                      })}
                            </Text>
                        </div>
                    ),
                    modalBoxView: (
                        <div>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.accountAlert.invoice.incoming') + ' '}
                                {fromTodayToDate(bot?.next_payment_date)?.day > 0
                                    ? t('modal.accountAlert.invoice.incoming.day', {
                                          count: fromTodayToDate(bot?.next_payment_date)?.day,
                                      })
                                    : t('modal.accountAlert.invoice.incoming.hour', {
                                          hour: fromTodayToDate(bot?.next_payment_date)?.hour,
                                          min: fromTodayToDate(bot?.next_payment_date)?.minute,
                                      })}
                            </Text>
                            <div className={'flex items-center gap-x-[5px] mt-[3px]'}>
                                <ExchangeTag
                                    removeText={true}
                                    className={'flex-none w-[14px]'}
                                    exchange={me?.keys?.find(item => item?.key_id === bot?.key_id)?.exchange}
                                />
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    {bot?.copier_name ?? getExchange(bot?.exchange)?.display_name}
                                </Text>
                                <IconNextArrow className={'w-[14px] fill-gray03 dark:fill-dark_gray03 flex-none'} />
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02'}>
                                    {bot?.leader_name}
                                </Text>
                            </div>
                        </div>
                    ),
                    actionText: t('accountAlert.button.text.invoices.incoming'),
                    action: () => {
                        createLog('event', 'account_important_alert_click', {type: 'invoice_incoming'})
                        router?.push(getLinkUrl(URL_ACCOUNT_INVOICE))
                    },
                })
            })
        }

        // exchange key error
        if (inActiveExchange && inActiveExchange?.length > 0) {
            inActiveExchange?.forEach(api => {
                if (!ignoredNotification?.includes(`api_inactive_${api?.key_id}`)) {
                    list?.push({
                        id: `account_alert_api_inactive_${api?.key_id}`,
                        type: 'api_inactive',
                        accountBoxView: (
                            <div>
                                <TagText
                                    className={
                                        'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.key.error', {exchange: getExchange(api?.exchange)?.display_name})}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_inactive')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        modalBoxView: (
                            <div>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {t('modal.accountAlert.key.error')}
                                </Text>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                    {t('modal.accountAlert.key.error.desc', {
                                        exchange: getExchange(api?.exchange)?.display_name,
                                    })}
                                </Text>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_inactive')}
                                    className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        actionText: t('accountAlert.button.text.key'),
                        action: () => {
                            createLog('event', 'account_important_alert_click', {type: 'api_inactive'})
                            router?.push(getLinkUrl(URL_ACCOUNT_API))
                        },
                    })
                }
            })
        }

        // upbit key update
        if (expireSoonExchange && expireSoonExchange?.length > 0) {
            expireSoonExchange?.forEach(api => {
                if (!ignoredNotification?.includes(`api_expire_${api?.key_id}`)) {
                    list?.push({
                        id: `account_alert_api_expire_${api?.key_id}`,
                        type: 'api_expire',
                        accountBoxView: (
                            <div>
                                <TagText
                                    className={
                                        'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.key.upbit.expired', {
                                        exchange: getExchange(api?.exchange)?.display_name,
                                    })}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_expire')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        modalBoxView: (
                            <div>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {t('modal.accountAlert.key.upbit.expired')}
                                </Text>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                    {t('modal.accountAlert.key.upbit.expired.desc', {
                                        exchange: getExchange(api?.exchange)?.display_name,
                                    })}
                                </Text>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_expire')}
                                    className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        actionText: t('accountAlert.button.text.key.guide'),
                        action: () => {
                            createLog('event', 'account_important_alert_click', {type: 'api_expire'})
                            router?.push(getLinkUrl(URL_ACCOUNT_API))
                        },
                    })
                }
            })
        }

        // bybit permission
        if (missingPermissionExchange && missingPermissionExchange?.length > 0) {
            missingPermissionExchange?.forEach(api => {
                if (!ignoredNotification?.includes(`api_permission_${api?.key_id}`)) {
                    list?.push({
                        id: `account_alert_api_permission_${api?.key_id}`,
                        type: 'api_permission',
                        accountBoxView: (
                            <div>
                                <TagText
                                    className={
                                        'text-body2  sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.key.bybit.permission')}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_permission')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        modalBoxView: (
                            <div>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {t('modal.accountAlert.key.bybit.permission')}
                                </Text>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                    {t('modal.accountAlert.key.bybit.permission.desc')}
                                </Text>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_permission')}
                                    className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        actionText: t('accountAlert.button.text.key.guide'),
                        action: () => {
                            createLog('event', 'account_important_alert_click', {type: 'api_permission'})
                            router?.push(getLinkUrl(URL_ACCOUNT_API))
                        },
                    })
                }
            })
        }

        // binance ip not set
        if (noIpBinanceExchange && noIpBinanceExchange?.length > 0) {
            noIpBinanceExchange?.forEach(api => {
                if (!ignoredNotification?.includes(`api_binance_noIP_${api?.key_id}`)) {
                    list?.push({
                        id: `account_alert_api_binance_noIP_${api?.key_id}`,
                        type: 'api_binance_noIP',
                        accountBoxView: (
                            <div>
                                <TagText
                                    className={
                                        'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.key.binance.ip')}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_binance_noIP')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        modalBoxView: (
                            <div>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                    {t('modal.accountAlert.key.binance.ip')}
                                </Text>
                                <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                    {t('modal.accountAlert.key.binance.ip.desc')}
                                </Text>
                                <Text
                                    onClick={() => removeAlert(api?.key_id, 'api_binance_noIP')}
                                    className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        ),
                        actionText: t('accountAlert.button.text.key.guide'),
                        action: () => {
                            createLog('event', 'account_important_alert_click', {type: 'api_binance_noIP'})
                            router?.push(getLinkUrl(URL_ACCOUNT_API))
                        },
                    })
                }
            })
        }

        // pro used and pro ip not set
        if (me?.is_pro && notConnectedExchanges && notConnectedExchanges?.length > 0) {
            list?.push({
                id: `account_alert_api_not_updated_pro`,
                type: 'api_not_updated_pro',
                accountBoxView: (
                    <div>
                        <Text
                            className={
                                'text-body2 sm:text-body3 sm:font-normal text-red_shade dark:text-dark_red_shade inline'
                            }>
                            {t('accountAlert.key.pro.set.tag')}
                        </Text>
                        <div className={'inline'}>
                            <div className={'inline-flex items-center gap-x-[5px] px-[5px]'}>
                                {notConnectedExchanges?.map(key => (
                                    <ExchangeTag
                                        removeText={true}
                                        key={key?.key_id}
                                        exchange={key?.exchange}
                                        iconSize={12}
                                    />
                                ))}
                            </div>
                        </div>
                        <TagText
                            className={
                                'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01  inline'
                            }>
                            {t('accountAlert.key.pro.set')}
                        </TagText>
                    </div>
                ),
                modalBoxView: (
                    <div>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.accountAlert.key.pro.set')}
                        </Text>
                        <div className={'mt-[3px]'}>
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02 inline'}>
                                {t('modal.accountAlert.key.pro.set.desc')}
                            </Text>
                            <div className={'inline'}>
                                <div className={'inline-flex items-center gap-x-[5px] pr-[5px]'}>
                                    {notConnectedExchanges?.map(key => (
                                        <ExchangeTag
                                            removeText={true}
                                            key={key?.key_id}
                                            exchange={key?.exchange}
                                            iconSize={12}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                actionText: t('accountAlert.button.text.key'),
                action: () => {
                    createLog('event', 'account_important_alert_click', {type: 'api_not_updated_pro'})
                    router?.push(getLinkUrl(URL_ACCOUNT_API))
                },
            })
        }

        // pro expired incoming (and copy trading)
        if (proExpireAtIncoming) {
            if (copyBot?.bots?.filter(bot => bot?.exchange === 'bybit')) {
                list?.push({
                    id: `account_alert_pro_expire_copyTrade`,
                    type: 'pro_expire_copyTrade',
                    accountBoxView: (
                        <div>
                            <div className={''}>
                                <TagText
                                    className={
                                        'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.pro.until.3days.copytrading', {exchange: 'Bybit'})}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(me?.pro_until, 'pro_expire_copyTrade')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        </div>
                    ),
                    modalBoxView: (
                        <div>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.accountAlert.pro.unitl.3days.copytrading')}
                            </Text>
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                {t('modal.accountAlert.pro.unitl.3days.copytrading.desc', {exchange: 'Bybit'})}
                            </Text>
                            <Text
                                onClick={() => removeAlert(me?.pro_until, 'pro_expire_copyTrade')}
                                className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                {t('accountAlert.remove.alert')}
                            </Text>
                        </div>
                    ),
                    actionText: t('accountAlert.button.text.pro'),
                    action: () => {
                        createLog('event', 'account_important_alert_click', {type: 'pro_expire_copyTrade'})
                        router?.push(getLinkUrl(URL_ACCOUNT_PRO))
                    },
                })
                // not copytrading
            } else {
                list?.push({
                    id: `account_alert_pro_expire`,
                    type: 'pro_expire',
                    accountBoxView: (
                        <div>
                            <div className={''}>
                                <TagText
                                    className={
                                        'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'
                                    }>
                                    {t('accountAlert.pro.unitl.3days')}
                                </TagText>
                                <Text
                                    onClick={() => removeAlert(me?.pro_until, 'pro_expire')}
                                    className={
                                        'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                    }>
                                    {t('accountAlert.remove.alert')}
                                </Text>
                            </div>
                        </div>
                    ),
                    modalBoxView: (
                        <div>
                            <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                {t('modal.accountAlert.pro.until.3days')}
                            </Text>
                            <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                                {t('modal.accountAlert.pro.until.3days.desc')}
                            </Text>
                            <Text
                                onClick={() => removeAlert(me?.pro_until, 'pro_expire')}
                                className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                                {t('accountAlert.remove.alert')}
                            </Text>
                        </div>
                    ),
                    actionText: t('accountAlert.button.text.pro'),
                    action: () => {
                        createLog('event', 'account_important_alert_click', {type: 'pro_expire'})
                        router?.push(getLinkUrl(URL_ACCOUNT_PRO))
                    },
                })
            }
        }

        // pro expired and copy trading
        if (proExpiredCopyTrading) {
            list?.push({
                id: `account_alert_pro_expire_end`,
                type: 'pro_expire_end',
                accountBoxView: (
                    <div>
                        <div className={''}>
                            <TagText
                                className={'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'}>
                                {t('accountAlert.pro.expired.copytrading', {exchange: 'Bybit'})}
                            </TagText>
                            <Text
                                onClick={() => removeAlert(me?.pro_until, 'pro_expire_end')}
                                className={
                                    'text-body2 w-fit sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01 underline cursor-pointer'
                                }>
                                {t('accountAlert.remove.alert')}
                            </Text>
                        </div>
                    </div>
                ),
                modalBoxView: (
                    <div>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.accountAlert.pro.expired.copytrading', {exchange: 'Bybit'})}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                            {t('modal.accountAlert.pro.expired.copytrading.desc', {exchange: 'Bybit'})}
                        </Text>
                        <Text
                            onClick={() => removeAlert(me?.pro_until, 'pro_expire_end')}
                            className={'text-body3 text-gray02 dark:text-dark_gray02 underline cursor-pointer'}>
                            {t('accountAlert.remove.alert')}
                        </Text>
                    </div>
                ),
                actionText: t('accountAlert.button.text.pro'),
                action: () => {
                    createLog('event', 'account_important_alert_click', {type: 'pro_expire_end'})
                    router?.push(getLinkUrl(URL_ACCOUNT_PRO))
                },
            })
        }

        // email confirm
        if (checkHasEmail) {
            list?.push({
                id: `email_connect`,
                type: 'email_connect',
                accountBoxView: (
                    <div>
                        <TagText
                            className={'text-body2 sm:text-body3 sm:font-normal text-gray01 dark:text-dark_gray01'}>
                            {t('accountAlert.email.confirm')}
                        </TagText>
                    </div>
                ),
                modalBoxView: (
                    <div>
                        <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.accountAlert.email.confirm')}
                        </Text>
                        <Text className={'text-body3 text-gray02 dark:text-dark_gray02 mt-[3px]'}>
                            {t('modal.accountAlert.email.confirm.desc')}
                        </Text>
                    </div>
                ),
                actionText: t('accountAlert.button.text.email'),
                action: () => {
                    if (router?.asPath !== '/account/setting') {
                        createLog('event', 'account_important_alert_click', {type: 'email_connect'})
                        router?.push(URL_ACCOUNT_SETTING)
                    }
                },
            })
        }
        setAlertList(list)
        setIsLoading(false)
    }, [
        isLogin,
        unpaidInvoice,
        incomingInvoice,
        inActiveExchange,
        ignoredNotification,
        expireSoonExchange,
        missingPermissionExchange,
        noIpBinanceExchange,
        notConnectedExchanges,
        proExpireAtIncoming,
        proExpiredCopyTrading,
        checkHasEmail,
        t,
        me?.pro_until,
        me?.is_pro,
        copyBot?.bots,
    ])

    return (
        <ImportantAlertsContext.Provider
            value={{
                alertList,
                alertLength: alertList.length,
                isLoading: isLoading,
            }}>
            {children}
        </ImportantAlertsContext.Provider>
    )
}

export function useImportantAlertsContext() {
    return useContext(ImportantAlertsContext)
}
