import React from 'react'
import Text from '@component/Text/Text'
import IconNext from '@svg/common/ic_btn_next.svg'
import useDebounce from '@hook/useDebounce'
import Spinner from '@component/Spinner/Spinner'
import {isNotEmpty} from '@util/strings'
import useDark from '@hook/useDark'

type SIZE = 'smallest' | 'small' | 'medium' | 'large'
type BUTTON_TYPE =
    | 'border'
    | 'primary'
    | 'secondary'
    | 'golden'
    | 'red'
    | 'border_red'
    | 'gray'
    | 'indigo'
    | 'white'
    | 'transparent'

interface IProps {
    text?: string
    size?: SIZE
    buttonType?: BUTTON_TYPE
    showArrow?: boolean
    onClick?: (e?: any) => void
    disabled?: boolean
    renderRightArea?: JSX.Element
    renderLeftArea?: JSX.Element
    className?: string
    textClassName?: string
    isLoading?: boolean
    overrideRoundnessClassName?: string
    dataCy?: string
    inlineStyle?: any
    inlineTextStyle?: any
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    isLink?: boolean
    disabledAction?: () => void
}

const WizButton: React.FC<IProps> = ({
    text,
    size = 'medium',
    buttonType = 'secondary',
    disabled = false,
    onClick,
    className,
    renderRightArea,
    renderLeftArea,
    showArrow = false,
    textClassName,
    isLoading = false,
    overrideRoundnessClassName,
    dataCy,
    inlineStyle,
    inlineTextStyle,
    onMouseEnter,
    onMouseLeave,
    isLink = false,
    disabledAction,
}) => {
    const {isRenderDark} = useDark()
    const buttonTypeClass = () => {
        if (disabled) {
            switch (buttonType) {
                case 'border':
                case 'border_red':
                    return `!bg-white dark:!bg-bg_dark_background ring-[1px] !ring-gray07 dark:!ring-dark_gray07  ${
                        disabledAction && 'cursor-pointer'
                    }`
                default:
                    return `!bg-gray07 dark:!bg-dark_gray07  ${disabledAction && 'cursor-pointer'}`
            }
        }

        switch (buttonType) {
            case 'border':
            case 'border_red':
                switch (size) {
                    case 'small':
                    case 'smallest':
                        return 'ring-[1px] ring-gray06 dark:ring-dark_gray06 bg-white dark:bg-bg_dark_white03 hover:bg-gray07 dark:hover:bg-dark_gray07'
                    default:
                        return 'ring-[1px] ring-gray05 dark:ring-dark_gray05 bg-white dark:bg-bg_dark_white03 hover:bg-gray07 dark:hover:bg-dark_gray07'
                }
            case 'white':
                return 'bg-white dark:bg-bg_dark_white03 hover:bg-gray06 dark:hover:bg-dark_gray06'
            case 'primary':
                return 'bg-primary dark:bg-dark_primary hover:bg-primary_shade dark:hover:bg-dark_primary_shade ring-[1px] !ring-transparent'
            case 'secondary':
                return 'bg-blue dark:bg-dark_blue hover:bg-blue_shade dark:hover:bg-dark_blue_shade ring-[1px] !ring-transparent'
            case 'golden':
                return 'bg-brown dark:bg-dark_brown hover:bg-brown_shade dark:hover:bg-dark_brown_shade ring-[1px] !ring-transparent'
            case 'red':
                return 'bg-red dark:bg-dark_red hover:bg-red_shade dark:hover:bg-dark_red_shade ring-[1px] !ring-transparent'
            case 'indigo':
                return 'bg-indigo dark:bg-dark_indigo hover:bg-indigo_shade dark:hover:bg-dark_indigo_shade ring-[1px] !ring-transparent'
            case 'gray':
                switch (size) {
                    case 'small':
                        return 'bg-gray09 dark:bg-dark_gray09 hover:bg-gray07 dark:hover:bg-dark_gray07 ring-[1px] ring-gray07 dark:ring-dark_gray07 hover:ring-gray06 dark:hover:ring-dark_gray06'
                    default:
                        return 'bg-gray07 dark:bg-dark_gray07 hover:bg-gray06 dark:hover:bg-dark_gray06 ring-[1px] !ring-transparent'
                }
            case 'transparent':
                return 'bg-transparent ring-[1px] !ring-transparent'
        }
    }

    const buttonPaddingClass = () => {
        switch (size) {
            case 'smallest':
                if (showArrow || renderRightArea || renderLeftArea) return 'pl-[7px] pr-[5px] py-6px]'
                return 'px-[7px] py-[6px]'
            case 'small':
                if (showArrow || renderRightArea || renderLeftArea) return 'pl-[15px] pr-[10px] py-[8.5px]'
                else return 'px-[15px] py-[8.5px]'
            case 'medium':
                if (showArrow || renderRightArea || renderLeftArea) return 'pl-[20px] pr-[10px] py-[12px]'
                else return 'px-[20px] py-[12px]'
            case 'large':
                return 'px-[40px] py-[17px]'
        }
    }

    const buttonRoundnessClass = () => {
        if (isNotEmpty(overrideRoundnessClassName)) {
            return overrideRoundnessClassName
        }

        switch (size) {
            case 'small':
            case 'smallest':
                return 'rounded-[3px]'
            case 'medium':
                return 'rounded-[3px]'
            case 'large':
                return 'rounded-[5px]'
        }
    }

    const textSizeClassName = () => {
        switch (size) {
            case 'smallest':
                return 'text-body3'
            case 'small':
                return 'text-ti3'
            case 'medium':
                return 'text-[14px] text-btn'
            case 'large':
                return 'text-[14px] text-btn'
        }
    }

    const textColorClassName = () => {
        if (disabled) {
            switch (buttonType) {
                case 'border':
                case 'border_red':
                    return 'text-gray04 dark:text-dark_gray04 bg-white dark:bg-bg_dark_white03 ring-[1px] ring-gray07 dark:ring-dark_gray07'
                default:
                    return 'text-gray04 dark:text-dark_gray04 bg-gray07 dark:bg-dark_gray07'
            }
        }

        switch (buttonType) {
            case 'border':
                switch (size) {
                    case 'small':
                        return 'text-gray02 dark:text-dark_gray02'
                    default:
                        return 'text-gray01 dark:text-dark_gray01'
                }
            case 'border_red':
                return 'text-red dark:text-dark_red'
            case 'primary':
            case 'secondary':
            case 'golden':
            case 'red':
            case 'indigo':
                return 'text-white'
            case 'gray':
            case 'white':
            case 'transparent':
                return 'text-gray01 dark:text-dark_gray01'
        }
    }

    const spaceClassName = () => {
        switch (size) {
            case 'small':
            case 'smallest':
                return 'space-x-[0px]'
            case 'medium':
            case 'large':
                return 'space-x-[10px]'
        }
    }

    const renderRightIcon = () => {
        if (showArrow) {
            return renderArrowIcon()
        } else if (renderRightArea) {
            return renderRightArea
        }
    }

    const renderLeftIcon = () => {
        if (renderLeftArea) {
            return renderLeftArea
        }
    }

    const renderSpinnerColor = () => {
        switch (buttonType) {
            case 'border':
                return isRenderDark ? '#27C89B' : '#0AAF82'
            case 'border_red':
                return isRenderDark ? '#E34E4E' : '#E6434E'
            case 'primary':
                return '#ffffff'
            case 'secondary':
                return '#ffffff'
            case 'golden':
                return '#ffffff'
            case 'red':
            case 'indigo':
                return '#ffffff'
            case 'gray':
                return isRenderDark ? '#6BC6AD' : '#088C68'
        }
    }

    const renderArrowIcon = () => {
        let fillClassName
        if (disabled) {
            fillClassName = 'fill-gray04 dark:fill-dark_gray04'
        } else {
            switch (buttonType) {
                case 'border':
                    fillClassName = 'fill-gray02 dark:fill-dark_gray02'
                    break
                case 'primary':
                case 'secondary':
                case 'golden':
                case 'red':
                case 'indigo':
                    fillClassName = 'fill-white'
                    break
            }
        }

        let sizeClassName
        switch (size) {
            case 'small':
            case 'smallest':
                sizeClassName = 'w-[12px]'
                break
            case 'medium':
                sizeClassName = 'w-[14px]'
                break
        }

        return <IconNext className={`${fillClassName} ${sizeClassName}`} />
    }

    const onClickButton = useDebounce(() => {
        onClick && onClick()
    }, 300)

    const onClickDisabledAction = useDebounce(() => {
        disabledAction && disabledAction()
    }, 300)

    return (
        <button
            style={{...inlineStyle}}
            data-cy={dataCy}
            className={`box-border flex ${buttonPaddingClass()} ${buttonTypeClass()} ${spaceClassName()} items-center ${textSizeClassName()} ${textColorClassName()} ${buttonRoundnessClass()} justify-center ${className} `}
            onClick={e => {
                if (isLoading) return
                if (disabled && e?.cancelable) {
                    onClickDisabledAction()
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    onClickButton()
                    if (onClick && !isLink && e?.cancelable) {
                        e.preventDefault()
                        e.stopPropagation()
                    }
                }
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={disabledAction ? false : disabled}>
            {renderLeftIcon()}
            <>
                {!isLoading && (
                    <Text
                        inlineStyle={{...inlineTextStyle}}
                        enablePreWhiteSpace={false}
                        className={`${textClassName} whitespace-nowrap`}>
                        {text}
                    </Text>
                )}
                <Spinner visible={isLoading} color={`${renderSpinnerColor()}`} />
            </>
            {renderRightIcon()}
        </button>
    )
}

export default WizButton
