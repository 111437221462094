import {apiPost} from '@api/api'
import {ApiRes} from '@type/api'
import {IConfirmResponse} from '@api/auths/register/Register'
import {safeReturn} from '@util/etc'

export const apiEditEmail = async (email?: string, email_code?: string): Promise<ApiRes<IConfirmResponse>> => {
    return await apiPost('edit', {
        email: safeReturn(email),
        email_code: safeReturn(email_code),
    })
}
