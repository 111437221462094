import React, {useEffect, useImperativeHandle} from 'react'
import useTimer from '@hook/useTimer'
import Text from '@component/Text/Text'

interface IProps {
    initialTime: number
    className?: string
    onStarted?: () => void
    onEnded?: () => void
}

const InputTimer = ({initialTime, className, onStarted, onEnded}: IProps, ref: any) => {
    const {timeLeft, startTimer, stopTimer} = useTimer({
        onStarted: onStarted,
        onEnded: onEnded,
    })

    useImperativeHandle(ref, () => ({
        startTimer: () => {
            startTimer(initialTime)
        },
    }))

    useEffect(() => {
        startTimer(initialTime)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatTimeLeft = (time: number) => {
        let timeSec = Math.floor(time % 60)
        let timeMin = Math.floor(time / 60)

        let minutes = '00'
        if (timeMin < 10) {
            minutes = `0${timeMin}`
        } else {
            minutes = `${timeMin}`
        }

        let seconds = '00'
        if (timeSec < 10) {
            seconds = `0${timeSec}`
        } else {
            seconds = `${timeSec}`
        }

        return minutes + ':' + seconds
    }

    return (
        <Text className={`${className} text-ti3 text-red_shade dark:text-dark_red_shade`}>
            {formatTimeLeft(timeLeft)}
        </Text>
    )
}

export default React.forwardRef(InputTimer)
