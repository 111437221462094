import React from 'react'
import AccountLayout from '@feature/account/layouts/AccountLayout'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import PortfolioLayout from '@feature/portfolio/components/layouts/PortfolioLayout/PortfolioLayout'
import KeyPortfolioLayout from '@feature/portfolio/components/layouts/PortfolioLayout/KeyPortfolioLayout'
import {equalString} from '@util/strings'

interface IPageLayout {
    pathname: string
    pageElement: React.ReactNode
    query?: any
    pageProps?: any
}

export function getPageLayout({pathname, pageElement, query, pageProps}: IPageLayout): React.ReactNode {
    // Account Layout
    if (pathname.startsWith('/account')) {
        // 만약 Pro Payment거나 /invoice/[id] 페이지라면
        if (
            pathname.startsWith('/account/pro/payment') ||
            pathname.startsWith('/account/invoice/[id]') ||
            pathname.startsWith('/account/wallet/withdraw') ||
            pathname.startsWith('/account/wallet/deposit')
        ) {
            return (
                <PageConfig mainClassName={'bg-white dark:bg-bg_dark_background'} requireLogin={true}>
                    {pageElement}
                </PageConfig>
            )
        }
        return (
            <PageConfig mainClassName={'bg-white dark:bg-bg_dark_background'} requireLogin={true}>
                <AccountLayout>{pageElement}</AccountLayout>
            </PageConfig>
        )
    }

    // User Portfolio Layout
    if (pathname.startsWith('/user/')) {
        if (pathname?.startsWith('/user/[id]/portfolio')) {
            if (!pageProps?.isUserData) {
                return (
                    <PageConfig enableFloatingNavigation={true} mainClassName={'sm:w-full'} bodyClassName={'sm:w-full'}>
                        {pageElement}
                    </PageConfig>
                )
            }

            return (
                <PageConfig enableFloatingNavigation={true} mainClassName={'sm:w-full'} bodyClassName={'sm:w-full'}>
                    <PortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        bgContainerClassName={'bg-gray09 dark:bg-dark_gray09 pt-[50px] md:pt-[60px] sm:w-full'}
                        showNavigation={pageProps?.isMe}
                        isMe={pageProps?.isMe}>
                        {pageElement}
                    </PortfolioLayout>
                </PageConfig>
            )
        }

        if (pathname?.startsWith('/user/[id]/copyfolio')) {
            return (
                <PageConfig
                    enableFloatingNavigation={true}
                    mainClassName={'sm:w-full'}
                    bodyClassName={'sm:w-full'}
                    requireLogin={true}>
                    <PortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        bgContainerClassName={'bg-blue_tint02 dark:bg-dark_blue_tint02 pt-[50px] md:pt-[60px]'}
                        showNavigation={pageProps?.isMe}
                        isMe={pageProps?.isMe}>
                        {pageElement}
                    </PortfolioLayout>
                </PageConfig>
            )
        }

        if (pathname?.startsWith('/user/[id]/leaderfolio')) {
            return (
                <PageConfig
                    enableFloatingNavigation={true}
                    mainClassName={'sm:w-full'}
                    bodyClassName={'sm:w-full'}
                    requireLogin={true}>
                    <PortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        isMe={pageProps?.isMe}
                        bgContainerClassName={'bg-brown_tint02 dark:bg-dark_brown_tint02 pt-[50px] md:pt-[60px]'}
                        showNavigation={pageProps?.isMe}>
                        {pageElement}
                    </PortfolioLayout>
                </PageConfig>
            )
        }
    }

    // CopyTrading Bot Pnl Layout (Same Copyfolio)
    if (pathname?.startsWith('/bot/[id]/pnl')) {
        return (
            <PageConfig
                enableFloatingNavigation={true}
                mainClassName={'sm:w-full'}
                bodyClassName={'sm:w-full'}
                requireLogin={true}>
                <KeyPortfolioLayout
                    login_id={pageProps?.login_id}
                    id={pageProps?.key_id}
                    bgContainerClassName={'bg-blue_tint02 dark:bg-dark_blue_tint02 pt-[50px] md:pt-[60px]'}
                    showNavigation={pageProps?.isMe}
                    isMe={pageProps?.isMe}>
                    {pageElement}
                </KeyPortfolioLayout>
            </PageConfig>
        )
    }

    // Key Portfolio Layout
    if (pathname?.startsWith('/key/[id]/portfolio')) {
        if (!pageProps?.isKey) {
            return (
                <PageConfig
                    enableFloatingNavigation={true}
                    mainClassName={'bg-white dark:bg-bg_dark_background'}
                    requireLogin={true}>
                    {pageElement}
                </PageConfig>
            )
        }

        if (pageProps?.isCopier && equalString(pageProps?.userId, pageProps?.login_id)) {
            return (
                <PageConfig
                    enableFloatingNavigation={true}
                    mainClassName={'bg-white dark:bg-bg_dark_background'}
                    requireLogin={true}>
                    <KeyPortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        showNavigation={pageProps?.isMe}
                        bgContainerClassName={`pt-[50px] md:pt-[60px] ${
                            query?.period === 'copy'
                                ? 'bg-blue_tint02 dark:bg-dark_blue_tint02'
                                : 'bg-gray09 dark:bg-dark_gray09'
                        }`}
                        isMe={pageProps?.isMe}>
                        {pageElement}
                    </KeyPortfolioLayout>
                </PageConfig>
            )
        } else if (pageProps?.isLeader) {
            return (
                <PageConfig mainClassName={'bg-white dark:bg-bg_dark_background'} enableFloatingNavigation={true}>
                    <KeyPortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        showNavigation={pageProps?.isMe}
                        bgContainerClassName={`pt-[50px] md:pt-[60px] ${
                            query?.period === 'leader'
                                ? 'bg-brown_tint02 dark:bg-dark_brown_tint02'
                                : 'bg-gray09 dark:bg-bg_dark_white01'
                        }`}
                        isMe={pageProps?.isMe}>
                        {pageElement}
                    </KeyPortfolioLayout>
                </PageConfig>
            )
        } else {
            return (
                <PageConfig
                    enableFloatingNavigation={true}
                    mainClassName={'bg-white dark:bg-bg_dark_background'}
                    requireLogin={true}>
                    <KeyPortfolioLayout
                        login_id={pageProps?.login_id}
                        id={pageProps?.id}
                        showNavigation={pageProps?.isMe}
                        isMe={pageProps?.isMe}
                        bgContainerClassName={'bg-gray09 dark:bg-dark_gray09 pt-[50px] md:pt-[60px]'}>
                        {pageElement}
                    </KeyPortfolioLayout>
                </PageConfig>
            )
        }
    }

    // Key Portfolio Layout (History, Pnl, Open Order)
    if (
        pathname?.startsWith('/key/[id]/history') ||
        pathname?.startsWith('/key/[id]/pnl') ||
        pathname?.startsWith('/key/[id]/open-order') ||
        pathname?.startsWith('/leaderbot/[id]/pnl')
    ) {
        return (
            <PageConfig enableFloatingNavigation={true}>
                <KeyPortfolioLayout
                    id={pageProps?.id}
                    login_id={pageProps?.login_id}
                    bgContainerClassName={'bg-gray09 dark:bg-dark_gray09 pt-[50px] md:pt-[60px]'}
                    showNavigation={pageProps?.isMe}
                    isMe={pageProps?.isMe}>
                    {pageElement}
                </KeyPortfolioLayout>
            </PageConfig>
        )
    }

    // Leader Key Portfolio Layout (Copiers, History, Orders, Pnl)
    if (
        pathname?.startsWith('/key/[id]/leaderfolio/copiers') ||
        pathname?.startsWith('/key/[id]/leaderfolio/history') ||
        pathname?.startsWith('/key/[id]/leaderfolio/orders') ||
        pathname?.startsWith('/key/[id]/leaderfolio/pnl')
    ) {
        return (
            <PageConfig enableFloatingNavigation={true}>
                <KeyPortfolioLayout
                    id={pageProps?.id}
                    login_id={pageProps?.login_id}
                    bgContainerClassName={'bg-brown_tint02 dark:bg-dark_brown_tint02 pt-[50px] md:pt-[60px]'}
                    showNavigation={pageProps?.isMe}
                    isMe={pageProps?.isMe}>
                    {pageElement}
                </KeyPortfolioLayout>
            </PageConfig>
        )
    }

    return pageElement
}
