import React, {useCallback, useMemo, useRef} from 'react'
import {CHART_COLORS} from '@component/charts/WizAreaChart/WizAreaChart'
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    ScriptableContext,
    Title,
    Tooltip,
} from 'chart.js/auto'
import {Chart} from 'react-chartjs-2'
import useWindowSize from '@hook/useWindowSize'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import {isEmptyArray} from '@util/array'
import {useTranslation} from 'next-i18next'
import useDark from '@hook/useDark'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

export type PortfolioAreaChartPeriod = '1' | '7' | '30' | '90' | '180' | '365' | '1000'
export type PortfolioAreaChartType =
    | 'balance'
    | 'earning'
    | 'profit'
    | 'adjusted_profit'
    | 'aPnl'
    | 'leader'
    | 'mwr'
    | 'twr'
export type PortfolioAreaChartData = {x: number; y: number; text?: string}

export interface IPortfolioColorPalette {
    plus?: string
    minus?: string
    neutral?: string
    leader?: string
}

interface IProps {
    width?: number
    height?: number
    chartData: PortfolioAreaChartData[]
    className?: string
    chartType: PortfolioAreaChartType
    chartPeriod?: PortfolioAreaChartPeriod
    isSimple?: boolean
    colorPalette?: {line?: IPortfolioColorPalette; gradient?: IPortfolioColorPalette}
    borderWidth?: number
    xFormat?: string
    yFormat?: string
}

const createHoverLinePlugin = (isRenderDark?: boolean) => ({
    id: 'hoverLine',
    afterTooltipDraw(chart, args, plugins) {
        const {
            ctx,
            tooltip,
            chartArea: {bottom},
            scales: {x},
        } = chart

        if (tooltip?._active?.length > 0) {
            const xCoor = x?.getPixelForValue(tooltip?.dataPoints?.at(0)?.dataIndex)
            ctx.save()
            ctx.beginPath()
            ctx.lineWidth = 1
            ;(ctx.strokeStyle = isRenderDark ? 'rgba(255,255,255,0.04)' : 'rgba(0,0,0,0.06)'),
                (ctx.globalCompositeOperation = 'source-over')
            ctx?.moveTo(xCoor, 11)
            ctx?.lineTo(xCoor, bottom)
            ctx?.stroke()
            ctx?.closePath()
        }
    },
})

const createHoverPointPlugin = (
    maxValue,
    minValue,
    len: number,
    type: PortfolioAreaChartType,
    isRenderDark?: boolean,
) => ({
    id: 'hoverPointPlugin',
    afterDraw(chart) {
        if (!maxValue || !minValue || len === 0) return
        const {ctx, tooltip} = chart

        ctx.save()

        const meta = chart?.getDatasetMeta(0)

        if (tooltip?._active?.length > 0 && meta?.data?.length > 0) {
            const highlightedPoint = meta.data[tooltip?.dataPoints?.at(0)?.dataIndex]

            if (highlightedPoint) {
                const x = highlightedPoint.x
                const y = highlightedPoint.y

                // Draw a circle at the highlighted point
                ctx.beginPath()
                ctx.arc(x, y, 3, 0, Math.PI * 2)
                if (type === 'balance') {
                    ctx.strokeStyle = CHART_COLORS.neutral
                } else if (type === 'leader') {
                    ctx.strokeStyle = isRenderDark ? CHART_COLORS.dark_leader : CHART_COLORS.leader
                } else if (minValue === maxValue && minValue === 0) {
                    ctx.strokeStyle = isRenderDark ? '#929499' : '#8A8F95'
                } else {
                    ctx.strokeStyle =
                        tooltip?.dataPoints?.at(0)?.parsed?.y < 0
                            ? isRenderDark
                                ? CHART_COLORS.dark_minus
                                : CHART_COLORS.minus
                            : tooltip?.dataPoints?.at(0).parsed?.y > 0
                            ? isRenderDark
                                ? CHART_COLORS.dark_plus
                                : CHART_COLORS.plus
                            : isRenderDark
                            ? '#929499'
                            : '#8A8F95'
                }
                ctx.lineWidth = 4
                ctx.stroke()
                ctx.fillStyle = '#FFFFFF'
                ctx.globalCompositeOperation = 'source-over'
                ctx.fill()
                ctx.restore()
                ctx.closePath()
            }
        }
    },
})
const getArrCutLength = (len?: number, type?: PortfolioAreaChartType, isSm?: boolean) => {
    if (len >= 100) {
        if (type === 'balance' || type === 'earning') {
            return isSm ? len / 2 - 5 : 30
        }
        return isSm ? Math.ceil(len / 20) : Math.ceil(len / 25)
    } else if (len >= 31) {
        if (type === 'balance' || type === 'earning') {
            return isSm ? 20 : 10
        }
        return isSm ? 8 : 5
    } else if (len > 8) {
        if (type === 'earning' || type === 'balance') {
            return isSm ? 4 : 3
        }
        return isSm ? 3 : 2
    } else {
        if (type === 'earning' || type === 'balance') {
            return 2
        }
        return 1
    }
}

const WizPortfolioAreaChart: React.FC<IProps> = ({
    width,
    className,
    chartType,
    chartData,
    isSimple = false,
    height,
    colorPalette,
    borderWidth,
    xFormat,
    yFormat,
}) => {
    const {t} = useTranslation()
    const {displayBalance, displayPercent, displayNumber} = useFormatNumber()
    const {displayDate} = useFormatDate()
    const {isSm} = useWindowSize()
    const chartRef = useRef<ChartJS>()
    const {isRenderDark} = useDark()

    const {minIndex, maxIndex, minValue, maxValue} = useMemo(() => {
        if (!chartData?.length || isSimple) {
            return {minIndex: 0, maxIndex: 0, minValue: 0, maxValue: 0}
        }
        const maxObj = chartData?.reduce((prev, value, index) => {
            return prev?.y >= value?.y ? prev : value
        })
        const minObj = chartData?.reduce((prev, value, index) => {
            return prev?.y <= value?.y ? prev : value
        })
        return {
            maxIndex: chartData?.findIndex(data => data?.y === maxObj?.y),
            minIndex: chartData?.findIndex(data => data?.y === minObj?.y),
            maxValue: maxObj?.y ?? 0,
            minValue: minObj?.y ?? 0,
        }
    }, [chartData, isSimple])

    const getRenderSign = useCallback(
        (data: string | number) => {
            const value = typeof data === 'string' ? Number(data) : data
            if (chartType === 'balance') {
                return displayBalance(value, {showPreSign: true, isShowFullNumber: true})
            } else if (chartType === 'earning') {
                return displayBalance(value, {showPreSign: true, isShowFullNumber: true})
            } else if (chartType === 'aPnl') {
                return `${displayNumber(value, 1)} σ`
            } else {
                return displayPercent(value)
            }
        },
        [chartType],
    )

    const shouldWriteYears = useMemo((): boolean => {
        if (isEmptyArray(chartData)) return false
        const firstTimeStamp = chartData[0]?.x
        const lastTimeStamp = chartData[chartData.length - 1]?.x

        if (firstTimeStamp === undefined || lastTimeStamp === undefined) return false

        const firstDataYear = new Date(firstTimeStamp).getFullYear()
        const LastDataYear = new Date(lastTimeStamp).getFullYear()
        const currentYear = new Date().getFullYear()

        return firstDataYear !== LastDataYear || LastDataYear !== currentYear
    }, [chartData])

    const getLineColor = useMemo(() => {
        if (!chartData || chartData?.length === 0) {
            return colorPalette?.line?.plus ?? isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
        }
        const lastElement = chartData?.at(chartData?.length - 1)
        if (lastElement?.y > 0)
            return colorPalette?.line?.plus ?? isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
        if (lastElement?.y < 0)
            return colorPalette?.line?.minus ?? isRenderDark ? CHART_COLORS.dark_minus : CHART_COLORS.minus
        return colorPalette?.line?.plus ?? isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus
    }, [chartData, colorPalette, isRenderDark])

    const data = useMemo(() => {
        let labels = []
        if (chartData?.length > 0) {
            labels = chartData.map(data => data.x)
        }

        const getGradientColor = (opacity = 1) => {
            if (chartType === 'leader') {
                return `rgba(168, 145, 96, ${opacity})`
            }
            if (!chartData || chartData?.length === 0 || chartType === 'balance') {
                if (chartType === 'balance') {
                    return `rgba(137, 199, 249, ${opacity})`
                } else {
                    return `rgba(10, 175, 130, ${opacity})`
                }
            }
            const lastElement = chartData?.at(chartData?.length - 1)
            if (lastElement?.y > 0) return `rgba(10, 175, 130, ${opacity})`
            if (lastElement?.y < 0) return `rgba(214, 35, 67, ${opacity})`
            return `rgba(10, 175, 130, ${opacity})`
        }

        return {
            labels,
            datasets: [
                {
                    fill:
                        chartType === 'balance' || isSimple || chartType === 'leader'
                            ? chartData?.at(chartData?.length - 1)?.y >= 0
                                ? 'start'
                                : 'end'
                            : {
                                  target: 'origin',
                                  above: 'rgba(10, 175, 130, 0.1)',
                                  below: 'rgba(214, 35, 67, 0.1)',
                              },
                    data: chartData?.map(data => {
                        return data.y
                    }),
                    borderColor:
                        chartType === 'leader'
                            ? isRenderDark
                                ? CHART_COLORS.dark_leader
                                : CHART_COLORS.leader
                            : chartType === 'balance'
                            ? CHART_COLORS.neutral
                            : !isSimple && minValue === maxValue && minValue === 0
                            ? isRenderDark
                                ? '#929499'
                                : '#8A8F95'
                            : getLineColor,
                    borderWidth: borderWidth ?? 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    lineTension: 0.3,
                    backgroundColor: (context: ScriptableContext<'line'>) => {
                        const ctx = context.chart.ctx
                        const isCheckChart =
                            chartType === 'balance' ||
                            chartData?.at(chartData?.length - 1)?.y >= 0 ||
                            chartType === 'leader'
                        const {chartArea} = context.chart
                        const gradient = ctx.createLinearGradient(
                            0,
                            isCheckChart ? context.chart.chartArea?.top ?? 75 : context.chart.chartArea?.bottom ?? 0,
                            0,
                            isCheckChart ? context.chart.chartArea?.bottom ?? 0 : context.chart.chartArea?.top ?? 75,
                        )
                        if (isCheckChart) {
                            gradient?.addColorStop(0, `${getGradientColor(0.3)}`)
                            gradient?.addColorStop(1, `${getGradientColor(0)}`)
                        } else {
                            gradient?.addColorStop(1, `${getGradientColor(0)}`)
                            gradient?.addColorStop(0, `${getGradientColor(0.3)}`)
                        }
                        return gradient
                    },
                },
            ],
        }
    }, [chartData, chartType, minValue, maxValue, getLineColor, isRenderDark, borderWidth, isSimple])

    const hoverLinePlugin = useMemo(() => createHoverLinePlugin(isRenderDark), [isRenderDark])

    const hoverPointPlugin = useMemo(
        () => createHoverPointPlugin(maxValue, minValue, chartData?.length, chartType, isRenderDark),
        [maxValue, minValue, chartData?.length, chartType, isRenderDark],
    )

    const minDrawPointPlugin = useMemo(() => {
        return {
            id: 'minDrawPointPlugin',
            afterDatasetDraw(chart) {
                if (minIndex === undefined || chartData?.length === 0) return
                if (minValue === undefined) return

                const {ctx} = chart
                const meta = chart?.getDatasetMeta(0)
                if (!meta?.data?.length) return

                const highlightedPoint = meta.data[minIndex]
                if (!highlightedPoint) return

                const x = highlightedPoint.x
                const y = highlightedPoint.y

                // 특정 구간을 나누는 ArrCutLength()
                const ArrCutLength = () => {
                    if (chartData?.length >= 100) {
                        if (chartType === 'balance' || chartType === 'earning') {
                            return isSm ? chartData?.length / 2 - 5 : 30
                        }
                        return isSm ? Math.ceil(chartData?.length / 20) : Math.ceil(chartData?.length / 25)
                    } else if (chartData?.length >= 31) {
                        if (chartType === 'balance' || chartType === 'earning') {
                            return isSm ? 20 : 10
                        }
                        return isSm ? 8 : 5
                    } else if (chartData?.length > 8) {
                        if (chartType === 'earning' || chartType === 'balance') {
                            return isSm ? 4 : 3
                        }
                        return isSm ? 3 : 2
                    } else {
                        
                        return 1
                    }
                }

                if (minIndex < ArrCutLength()) {
                    ctx.textAlign = 'left'
                    ctx.textBaseline = 'middle'
                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    ctx.fillText(`MIN ${getRenderSign(minValue)}`, x + 5, y + 16)
                } else if (minIndex >= chartData.length - ArrCutLength()) {
                    ctx.textAlign = 'right'
                    ctx.textBaseline = 'middle'

                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    // x 좌표를 살짝 왼쪽으로 옮겨서, 길어질 때 왼쪽으로 확장되도록
                    ctx.fillText(`MIN ${getRenderSign(minValue)}`, x - 5, y + 16)
                } else {
                    // 기존 Offset 계산
                    const xOffset = (() => {
                        if (chartData?.length === 1) return x - 24
                        if (chartData?.slice(0, ArrCutLength())?.some(item => item?.y === minValue)) {
                            return x + 7
                        } else if (
                            chartData?.slice(chartData.length - ArrCutLength())?.some(item => item?.y === minValue)
                        ) {
                            return x - 60
                        } else {
                            // 기존 코드에서는 x - 0 이었지만 보통은 약간 왼쪽이나 중앙 등으로 조정
                            return x - 0
                        }
                    })()

                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    // 기본 중앙 정렬 가정
                    ctx.textBaseline = 'middle'
                    ctx.textAlign = 'center'

                    ctx.fillText(`MIN ${getRenderSign(minValue)}`, xOffset, y + 16)
                }
            },
        }
    }, [chartData, minIndex, minValue, chartType, isRenderDark, isSm])

    const maxDrawPointPlugin = useMemo(() => {
        return {
            id: 'maxDrawPointPlugin',
            afterDatasetsDraw(chart) {
                if (!maxValue || maxIndex === undefined || chartData?.length === 0) return

                const {ctx} = chart
                const meta = chart?.getDatasetMeta(0)
                if (!meta?.data?.length) return

                const highlightedPoint = meta.data[maxIndex]
                if (!highlightedPoint) return

                const x = highlightedPoint.x
                const y = highlightedPoint.y

                // 특정 구간을 나누는 ArrCutLength()
                const ArrCutLength = () => {
                    if (chartData?.length >= 100) {
                        if (chartType === 'balance' || chartType === 'earning') {
                            return isSm ? chartData?.length / 2 - 5 : 30
                        }
                        return isSm ? Math.ceil(chartData?.length / 20) : Math.ceil(chartData?.length / 25)
                    } else if (chartData?.length >= 31) {
                        if (chartType === 'balance' || chartType === 'earning') {
                            return isSm ? 20 : 10
                        }
                        return isSm ? 8 : 5
                    } else if (chartData?.length > 8) {
                        if (chartType === 'earning' || chartType === 'balance') {
                            return isSm ? 4 : 3
                        }
                        return isSm ? 3 : 2
                    } else {

                        return 1
                    }
                }

                if (maxIndex < ArrCutLength()) {
                    ctx.textAlign = 'left'
                    ctx.textBaseline = 'middle'

                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    // 시작 부분이므로 x + 5 등으로 살짝 오른쪽 배치
                    ctx.fillText(`MAX ${getRenderSign(maxValue)}`, x + 5, y - 8)
                } else if (maxIndex >= chartData.length - ArrCutLength()) {
                    ctx.textAlign = 'right'
                    ctx.textBaseline = 'middle'

                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    // 끝 부분이므로 x - 5 정도로 오른쪽 정렬
                    ctx.fillText(`MAX ${getRenderSign(maxValue)}`, x - 5, y - 8)
                } else {
                    // 기존 Offset 계산
                    const getXOffset = () => {
                        if (chartData?.length === 1) return x - 24

                        // 앞쪽 구간에서 maxValue 발견
                        if (chartData?.slice(0, ArrCutLength())?.some(item => item?.y === maxValue)) {
                            return x + 7
                        }
                        // 뒤쪽 구간에서 maxValue 발견
                        else if (
                            chartData?.slice(chartData.length - ArrCutLength())?.some(item => item?.y === maxValue)
                        ) {
                            return x - 60
                        } else {
                            return x - 0
                        }
                    }

                    ctx.beginPath()
                    ctx.arc(x, y, 3, 0, Math.PI * 2)
                    ctx.fillStyle = isRenderDark ? '#F3F5F8' : '#242424'
                    ctx.globalCompositeOperation = 'source-over'
                    ctx.fill()
                    ctx.closePath()

                    ctx.font = 'bold 10px Roboto'
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'

                    ctx.fillText(`MAX ${getRenderSign(maxValue)}`, getXOffset(), y - 8)
                }
            },
        }
    }, [chartData, maxIndex, maxValue, chartType, isRenderDark, isSm])

    const borderColorPlugin = useMemo(() => {
        if (chartType === 'balance' || chartType === 'leader' || (minValue === maxValue && minValue === 0)) {
            return {id: 'borderColorPlugin'}
        }

        return {
            id: 'borderColorPlugin',
            afterLayout(chart) {
                if (!minValue || !minValue || chartData?.length === 0) return
                const {ctx} = chart

                ctx.save()
                let yAxis = chart.scales.y
                let yThreshold = yAxis.getPixelForValue(0)
                let gradient = ctx.createLinearGradient(0, yAxis.top, 0, yAxis.bottom)
                gradient?.addColorStop(0, isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus)
                let offset = (yThreshold - yAxis.top) / (yAxis.bottom - yAxis.top)
                if (offset < 1 && offset > 0) {
                    gradient?.addColorStop(offset, isRenderDark ? CHART_COLORS.dark_plus : CHART_COLORS.plus)
                    gradient?.addColorStop(offset, isRenderDark ? CHART_COLORS.dark_minus : CHART_COLORS.minus)
                    gradient?.addColorStop(1, isRenderDark ? CHART_COLORS.dark_minus : CHART_COLORS.minus)
                    chart.data.datasets[0].borderColor = gradient
                }
                ctx.restore()
            },
        }
    }, [chartType, minValue, maxValue, isRenderDark])

    const ChartsComponent = useCallback(() => {
        return (
            <Chart
                ref={chartRef}
                type={'line'}
                data={data}
                plugins={
                    isSimple || chartData?.length === 0
                        ? []
                        : [hoverPointPlugin, minDrawPointPlugin, maxDrawPointPlugin, hoverLinePlugin, borderColorPlugin]
                }
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            mode: 'index',
                            enabled: false,
                            intersect: false,
                            displayColors: false,
                            padding: {
                                top: 10,
                                bottom: 10,
                                left: 20,
                                right: 20,
                            },
                            external(args) {
                                if (isSimple) {
                                    return
                                }
                                const getOrCreateTooltip = chart => {
                                    let tooltipEl = chart?.canvas?.parentNode?.querySelector('div')

                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div')
                                        tooltipEl.style.background = 'rgba(255, 255, 255, 0.9)'
                                        tooltipEl.style.borderRadius = '10px'
                                        tooltipEl.style.color = '#8A8F95'
                                        tooltipEl.style.opacity = 1
                                        tooltipEl.style.pointerEvents = 'none'
                                        tooltipEl.style.position = 'absolute'
                                        tooltipEl.style.transition = 'all .1s ease'
                                        tooltipEl.style.boxShadow = '0px 4px 10px 0px rgba(0,0,0,0.1)'
                                        tooltipEl.style.padding = '10px 20px'

                                        const table = document.createElement('table')
                                        table.style.margin = '0px'

                                        tooltipEl.appendChild(table)
                                        chart.canvas.parentNode.appendChild(tooltipEl)
                                    }
                                    tooltipEl.style.transform =
                                        args?.tooltip?.dataPoints?.at(0)?.dataIndex < chartData?.length / 2
                                            ? 'translate(12%, -50%)'
                                            : 'translate(-110%, -50%)'
                                    return tooltipEl
                                }

                                const {chart, tooltip} = args
                                const tooltipEl = getOrCreateTooltip(chart)

                                if (tooltip.opacity === 0) {
                                    tooltipEl.style.opacity = 0
                                    return
                                }

                                if (tooltip?.dataPoints) {
                                    const titleLines = tooltip?.title || []
                                    const bodyLines = tooltip?.dataPoints.map((b, index) => {
                                        return getRenderSign(b?.raw as number)
                                    })

                                    const dateText = chartData?.at(tooltip?.dataPoints?.at(0)?.dataIndex)?.text
                                        ? chartData?.at(tooltip?.dataPoints?.at(0)?.dataIndex)?.text + ' UTC'
                                        : `${displayDate(
                                              chartData?.at(tooltip?.dataPoints?.at(0)?.dataIndex)?.x / 1000,
                                              t('dateFormat.date'),
                                          )} UTC`

                                    const tableHead = document.createElement('thead')
                                    titleLines.forEach(title => {
                                        const tr = document.createElement('tr')

                                        const th = document.createElement('th')

                                        const text = document.createTextNode(dateText)

                                        th.style.fontSize = '10px'
                                        th.style.fontFamily = 'NotoSansKR, Roboto'
                                        th.style.fontWeight = 'normal'
                                        th.style.lineHeight = '15px'
                                        th.style.textAlign = 'left'
                                        th.style.whiteSpace = 'nowrap'
                                        th.style.color = '#8A8F95'

                                        th.appendChild(text)
                                        tr.appendChild(th)
                                        tableHead.appendChild(tr)
                                    })

                                    const tableBody = document.createElement('tbody')
                                    bodyLines.forEach((body, i) => {
                                        const tr = document.createElement('tr')

                                        const td = document.createElement('td')

                                        const number = Number(tooltip?.dataPoints?.at(0)?.raw)

                                        td.style.fontSize = '20px'
                                        td.style.fontFamily = 'NotoSansKR, Roboto'
                                        td.style.fontWeight = 'bold'
                                        td.style.lineHeight = '30px'
                                        td.style.whiteSpace = 'nowrap'
                                        td.style.textAlign = 'left'
                                        td.style.color =
                                            chartType === 'balance'
                                                ? '#242424'
                                                : number < 0
                                                ? CHART_COLORS.minus
                                                : number > 0
                                                ? CHART_COLORS.plus
                                                : '#8A8F95'
                                        const text = document.createTextNode(body)

                                        td.appendChild(text)
                                        tr.appendChild(td)
                                        tableBody.appendChild(tr)
                                    })

                                    const tableRoot = tooltipEl.querySelector('table')

                                    while (tableRoot.firstChild) {
                                        tableRoot.firstChild.remove()
                                    }

                                    tableRoot.appendChild(tableHead)
                                    tableRoot.appendChild(tableBody)
                                }

                                const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas

                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1
                                tooltipEl.style.left = positionX + tooltip.caretX + 'px'
                                tooltipEl.style.top = positionY + tooltip.caretY + 'px'
                            },
                            caretSize: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            titleColor: '#606367',
                            titleFont: {
                                size: 10,
                                weight: 'normal',
                                lineHeight: '15px',
                                family: 'font-sans',
                            },
                            bodyFont: {
                                size: 20,
                                weight: 'bold',
                                lineHeight: '30px',
                                family: 'font-sans',
                            },
                            bodyAlign: 'left',
                            bodyColor(arg) {
                                if (chartType === 'leader') {
                                    return '#EDA54B'
                                }
                                if (chartType === 'balance') {
                                    return '#242424'
                                }
                                if ((arg?.tooltip?.dataPoints?.at(0).raw as number) === 0) {
                                    return '#242424'
                                } else if ((arg?.tooltip?.dataPoints?.at(0).raw as number) > 0) {
                                    return CHART_COLORS.plus
                                } else {
                                    return CHART_COLORS.minus
                                }
                            },
                            callbacks: !isSimple
                                ? {
                                      title(tooltipItems) {
                                          return displayDate(
                                              Number(tooltipItems?.at(0)?.label) / 1000,
                                              t(shouldWriteYears ? 'dateFormat.date' : 'dateFormat.shortDate', {
                                                  defaultValue: 'MMM d, yyyy',
                                              }),
                                          )
                                      },
                                      label(tooltipItem) {
                                          // tooltip label
                                          return getRenderSign(tooltipItem?.formattedValue)
                                      },
                                  }
                                : {},
                        },
                    },
                    hover: {
                        mode: 'index',
                        intersect: false,
                    },
                    animation: {
                        duration: isSimple ? 0 : 1000,
                    },
                    clip: false,
                    scales: {
                        x: {
                            display: !isSimple,
                            offset: chartData?.length === 1,
                            grid: {
                                display: false,
                            },
                            border: {
                                display: false,
                            },
                            ticks: {
                                maxTicksLimit: 5,
                                align: 'inner',
                                includeBounds: false,
                                color: isRenderDark ? '#929499' : '#8A8F95',
                                padding: 10,
                                font: {
                                    family: 'Roboto',
                                    size: 10,
                                    weight: 'normal',
                                    style: 'normal',
                                },
                                callback:
                                    !isSimple &&
                                    function (value, index, values) {
                                        let timeStamp = 0
                                        if (chartData?.length < 10 || isSm) {
                                            if (index === 0) {
                                                timeStamp = Number(chartData?.at(0)?.x)
                                            } else if (index === chartData?.length - 1) {
                                                timeStamp = Number(chartData?.at(-1)?.x)
                                            } else if (index === Math.floor(chartData?.length * 0.5)) {
                                                timeStamp = Number(
                                                    chartData?.at(Math.floor(chartData?.length * 0.5))?.x,
                                                )
                                            }
                                            if (timeStamp) {
                                                return displayDate(
                                                    Number(timeStamp) / 1000,
                                                    t(
                                                        xFormat
                                                            ? xFormat
                                                            : shouldWriteYears
                                                            ? 'dateFormat.date'
                                                            : 'dateFormat.shortDate',
                                                        {
                                                            defaultValue: 'MMM d, yyyy',
                                                        },
                                                    ),
                                                )
                                            } else {
                                                return
                                            }
                                        } else {
                                            if (index === 0) {
                                                timeStamp = Number(chartData?.at(0)?.x)
                                            } else if (index === chartData?.length - 1) {
                                                timeStamp = Number(chartData?.at(-1)?.x)
                                            } else if (index === Math.floor(chartData?.length * 0.25)) {
                                                timeStamp = Number(
                                                    chartData?.at(Math.floor(chartData?.length * 0.25))?.x,
                                                )
                                            } else if (index === Math.floor(chartData?.length * 0.5)) {
                                                timeStamp = Number(
                                                    chartData?.at(Math.floor(chartData?.length * 0.5))?.x,
                                                )
                                            } else if (index === Math.floor(chartData?.length * 0.75)) {
                                                timeStamp = Number(
                                                    chartData?.at(Math.floor(chartData?.length * 0.75))?.x,
                                                )
                                            }

                                            if (timeStamp) {
                                                return displayDate(
                                                    Number(timeStamp) / 1000,
                                                    t(
                                                        xFormat
                                                            ? xFormat
                                                            : shouldWriteYears
                                                            ? 'dateFormat.date'
                                                            : 'dateFormat.shortDate',
                                                        {
                                                            defaultValue: 'MMM d, yyyy',
                                                        },
                                                    ),
                                                )
                                            } else {
                                                return
                                            }
                                        }
                                    },
                            },
                        },
                        y: {
                            display: !isSimple,
                            offset: !isSimple,
                            ticks: {
                                maxTicksLimit: 7,
                                display: !isSm,
                                callback:
                                    !isSimple &&
                                    function (value, index, values) {
                                        return getRenderSign(value)
                                    },
                                padding: 10,
                                color: isRenderDark ? '#929499' : '#8A8F95',
                                font: {
                                    family: 'Roboto',
                                    size: 10,
                                    weight: 'normal',
                                    style: 'normal',
                                },
                            },
                            grid: {
                                drawTicks: false,
                                color: isRenderDark ? 'rgba(255,255,255,0.04)' : 'rgba(0,0,0,0.06)',
                            },
                            border: {
                                display: false,
                            },
                        },
                    },
                }}
            />
        )
    }, [data, isSm, isSimple, minDrawPointPlugin, maxDrawPointPlugin, isRenderDark, t, chartType])

    return (
        <div
            style={{width: width ?? '99%', height: height}}
            className={`relative max-h-[245px] mt-[10px] mx-auto ${className}`}>
            {<ChartsComponent />}
        </div>
    )
}

export default React.memo(WizPortfolioAreaChart)
