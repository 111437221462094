import React, {useMemo} from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import IconArrow from '@svg/common/icon_16.svg'

interface IProps {
    className?: string
    type?: 'normal' | 'copy' | 'leader'
}

const AssetTableHeaderDetailButton: React.FC<IProps> = ({className, type = 'normal'}) => {
    const {t} = useTranslation()

    const getTypeStyled = useMemo(() => {
        switch (type) {
            case 'normal':
                return 'group-hover:ring-primary group-hover:fill-primary_shade group-hover:text-primary_shade dark:group-hover:ring-dark_primary dark:group-hover:text-dark_primary_shade dark:group-hover:fill-dark_primary_shade'
            case 'copy':
                return 'group-hover:ring-blue group-hover:fill-blue_shade group-hover:text-blue_shade dark:group-hover:ring-dark_blue dark:group-hover:text-dark_blue_shade dark:group-hover:fill-dark_blue_shade'
            case 'leader':
                return 'group-hover:ring-brown group-hover:fill-brown_shade group-hover:text-brown_shade dark:group-hover:ring-dark_brown dark:group-hover:text-dark_brown_shade dark:group-hover:fill-dark_brown_shade'
        }
    }, [type])

    return (
        <button
            className={`${getTypeStyled} min-w-fit h-[21px] rounded-[3px] cursor-pointer bg-white dark:bg-bg_dark_white03 flex items-center py-[3px] px-[6px] ring-[1px] ring-gray06 dark:ring-dark_gray06 text-gray01 dark:text-dark_gray01 fill-gray01 dark:fill-dark_gray01 ${className}`}>
            <Text className={`text-body4 flex-none`}>{t('table.asset.header.button.detail')}</Text>
            <IconArrow className={'w-[9px] flex-none'} />
        </button>
    )
}

export default AssetTableHeaderDetailButton
