import {create} from 'zustand'
import {getCookie, removeCookie, setCookie} from '@util/cookie'
import {DAY_IN_SECOND} from '@constant/auth'
import * as amplitude from '@amplitude/analytics-browser'
import {isNotEmpty} from '@util/strings'
import {apiFetchMe} from '@api/user/account/me'
import {createLog} from '@util/logs'
import {USER_ACCOUNTS_KEY, USER_ID_ACCESS_TOKEN, USER_ID_KEY} from '@constant/key'
import {getLocalStorage, setLocalStorage} from '@util/localstorage'
import {setLogUserLogout, setUserMeProperties} from '@util/amplitude'
import {apiGetProfile} from '@api/user/profile/getProfile'

interface ILoginStore {
    id: string
    accessToken: string
    login: (id: string, accessToken: string, remember?: boolean) => void
    logout: () => void
    refreshLoginCookieExpireTime: () => void
}

export interface ILoginInfoData {
    id: string
    access_token: string
    thumbnail?: string
    name: string
    nickname?: string
    login_end_at: number
    isPro?: boolean
}

const cookieMaxAge = 3 * DAY_IN_SECOND

export const useLoginStore = create<ILoginStore>()((set, get) => {
    const setUserIdCookie = (userId?: string) => {
        setCookie(USER_ID_KEY, userId, {path: '/', maxAge: cookieMaxAge})
    }
    const setUserTokenCookie = (accessToken?: string) => {
        setCookie(USER_ID_ACCESS_TOKEN, accessToken, {path: '/', maxAge: cookieMaxAge})
    }
    const changeToNextUser = (accounts?: ILoginInfoData[]) => {
        // 저장된 계정 리스트에서 만료 시간 비교, 로그인 만료 시간이 지났다면 다음 유저를 찾아서 쿠키에 저장
        const now = new Date().getTime()
        const nextUserInfo: ILoginInfoData = accounts?.find(item => now < item?.login_end_at)

        // 저장된 계정이 없다면 쿠키 제거
        if (!nextUserInfo) {
            removeCookie(USER_ID_ACCESS_TOKEN, {path: '/'})
            removeCookie(USER_ID_KEY, {path: '/'})

            set(state => ({id: undefined, accessToken: undefined, me: undefined}))
            amplitude.setUserId(undefined)
            return
        }

        setUserTokenCookie(nextUserInfo?.access_token)
        setUserIdCookie(nextUserInfo?.id)
        set(state => ({id: nextUserInfo?.id, accessToken: nextUserInfo?.access_token}))
    }

    return {
        id: getCookie(USER_ID_KEY),
        accessToken: getCookie(USER_ID_ACCESS_TOKEN),
        login: async (id: string, accessToken: string) => {
            setUserTokenCookie(accessToken)
            setUserIdCookie(id)
            set(state => ({id: id, accessToken: accessToken}))

            const {data: me} = await apiFetchMe()
            const {data: profile} = await apiGetProfile(id)

            const loginInfoData: ILoginInfoData = {
                id: id,
                access_token: accessToken,
                thumbnail: me?.thumbnail || '',
                name: me?.name,
                nickname: me?.nicknames?.at(0)?.nickname ?? '',
                login_end_at: new Date().getTime() + 7 * DAY_IN_SECOND * 1000,
                isPro: me?.is_pro,
            }

            const userAccounts: ILoginInfoData[] = getLocalStorage(USER_ACCOUNTS_KEY)

            if (userAccounts) {
                // 리스트 중 닉네임 또는 네임이 없는 경우 제거
                const removeUnknownUserArr = userAccounts?.filter(item => item?.name || item?.nickname)

                const newDataArr = removeUnknownUserArr?.filter(item => item?.id !== id)
                setLocalStorage(USER_ACCOUNTS_KEY, [...newDataArr, loginInfoData])
            } else {
                setLocalStorage(USER_ACCOUNTS_KEY, [loginInfoData])
            }

            amplitude.setUserId(id)
            setUserMeProperties(me, profile)

            createLog('login')
        },
        logout: () => {
            const userAccounts: ILoginInfoData[] = getLocalStorage(USER_ACCOUNTS_KEY)

            const newAccounts = userAccounts?.filter(item => item?.id !== get().id)
            setLocalStorage(USER_ACCOUNTS_KEY, newAccounts)
            setLogUserLogout()

            changeToNextUser(newAccounts)
        },
        refreshLoginCookieExpireTime: () => {
            const accessToken = get().accessToken
            const userId = get().id

            if (isNotEmpty(accessToken) && isNotEmpty(userId)) {
                setUserTokenCookie(get().accessToken)
                setUserIdCookie(get().id)
            } else {
                const userAccounts: ILoginInfoData[] = getLocalStorage(USER_ACCOUNTS_KEY)

                // 만료된 계정은 리스트에서 제거 (마지막 로그인 계정)
                userAccounts?.pop()
                setLocalStorage(USER_ACCOUNTS_KEY, userAccounts)

                changeToNextUser(userAccounts)
            }
        },
    }
})
