import React, {useMemo} from 'react'
import {useChartSettingsStore} from '../../hooks/useChartSettingsStore'
import Text from '@component/Text/Text'
import WizSelectBox from '@component/select/WizSelectBox'
import TradingTokenRankModal from '../trading/TradingTokenRankModal'
import WizButton from '@component/buttons/WizButton'
import DownIcon from '@svg/common/ic_btn_down.svg'

interface IProps {
    className?: string
}

const ChartSearchSymbolButton: React.FC<IProps> = ({className}) => {
    const tradingTokenRankModalRef = React.useRef(null)

    const symbol = useChartSettingsStore(state => state.symbol)

    const onOpenTokenRankModal = () => {
        tradingTokenRankModalRef.current?.show()
    }

    return (
        <div className={`py-[7px] hidden md:flex ${className}`}>
            <WizButton
                text={symbol.base_symbol}
                buttonType={'border'}
                size={'small'}
                className={'h-[29px]'}
                textClassName={'text-btn_sm text-gray01 dark:text-dark_gray01 font-medium'}
                onClick={onOpenTokenRankModal}
                renderRightArea={
                    <DownIcon width={12} height={12} className={'!ml-[5px] fill-gray01 dark:fill-dark_gray01'} />
                }
            />
            <TradingTokenRankModal ref={tradingTokenRankModalRef} />
        </div>
    )
}
export default ChartSearchSymbolButton
