import {Dialog, Transition} from '@headlessui/react'
import React, {Fragment, useImperativeHandle, useState} from 'react'
import Text from '@component/Text/Text'
import IconModalClose from '@svg/common/ic_modal_close.svg'
import WizIcon from '@component/images/WizIcon'

interface IProps {
    title?: string
    titleIcon?: React.ReactNode
    children?: React.ReactNode
    onCloseCallback?: () => void
    hideTitle?: boolean
    hideClose?: boolean
    size?: TypeContentModalSize
    overrideStyle?: IModalStyle
    className?: string
    heightClassName?: string
    titleClassName?: string
    panelClassName?: string
    containerClassName?: string
    enabledOverFlowY?: boolean
}

export type TypeContentModalSize = '300' | '340' | '400' | '600' | '800'

interface IModalStyle {
    widthClassName?: string
}

const styleMap = new Map<TypeContentModalSize, IModalStyle>()
styleMap.set('300', {widthClassName: 'max-w-[300px] w-full'})
styleMap.set('340', {widthClassName: 'max-w-[340px] w-full'})
styleMap.set('400', {widthClassName: 'max-w-[400px] w-full'})
styleMap.set('600', {widthClassName: 'max-w-[600px] w-full'})
styleMap.set('800', {widthClassName: 'max-w-[800px] w-full'})

const CLOSE_ICON_SIZE = 30

const WizContentModal = (
    {
        title,
        titleIcon,
        children,
        onCloseCallback,
        className,
        heightClassName = '',
        titleClassName = '',
        panelClassName = '',
        containerClassName = '',
        size = '400',
        hideTitle = false,
        hideClose = false,
        enabledOverFlowY = true,
    }: IProps,
    ref: any,
) => {
    const [isOpen, setIsOpen] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            showModal()
        },
        close: () => {
            closeModal()
        },
    }))

    const closeModal = () => {
        setIsOpen(false)
        onCloseCallback && onCloseCallback()
    }

    const showModal = () => {
        setIsOpen(true)
    }

    const getPanelStyle = () => {
        const widthStyle = `${styleMap?.get(size)?.widthClassName} sm:w-full sm:max-w-[600px]`
        const heightStyle = `${heightClassName} sm:max-h-full sm:flex-1 ${enabledOverFlowY && 'overflow-y-auto'}`
        const paddingStyle = 'px-[30px] py-[20px] sm:px-[20px]'
        const roundedStyle = 'rounded-[5px] sm:rounded-none'
        const shadowStyle = 'shadow-xl sm:shadow-none'

        return `${widthStyle} ${heightStyle} ${paddingStyle} ${roundedStyle} ${shadowStyle}`
    }

    return (
        <Dialog open={isOpen} as="div" className="relative z-modal" unmount={true} onClose={closeModal}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0 bg-black bg-opacity-40 backdrop-blur-[3px]" />
            </Transition.Child>

            <div className={`fixed inset-0 overflow-y-auto ${className}`}>
                <div
                    className={`flex flex-col min-h-full items-center justify-center ${containerClassName}`}
                    onClick={e => {
                        if (e?.cancelable) {
                            e.preventDefault()
                        }
                        e.stopPropagation()
                    }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">
                        <Dialog.Panel className={`bg-white dark:bg-bg_dark_popup ${getPanelStyle()} ${panelClassName}`}>
                            {!hideTitle && (
                                <div className={`flex justify-between items-center  ${titleClassName}`}>
                                    <div className={'flex items-center gap-x-[5px]'}>
                                        {titleIcon}
                                        <Text className={`text-h3 sm:text-h4 text-gray01 dark:text-dark_gray01`}>
                                            {title}
                                        </Text>
                                    </div>
                                    {!hideClose && (
                                        <WizIcon
                                            onClick={closeModal}
                                            backgroundColorClassName={
                                                'p-[5px] fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 hover:bg-transparent01 dark:hover:bg-dark_transparent01 dark:hover:bg-dark_transparent01 cursor-pointer'
                                            }>
                                            <IconModalClose
                                                className={
                                                    'w-[20px] fill-gray02 dark:fill-dark_gray02 sm:fill-gray01 dark:sm:fill-dark_gray01'
                                                }
                                            />
                                        </WizIcon>
                                    )}
                                </div>
                            )}

                            <div>{children}</div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    )
}

export default React.forwardRef(WizContentModal)
