type IRegion = {
    url: string
    name: string
    code: string
    calling_code: string
}
export const regions: IRegion[] = [
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagaf.png',
        name: 'Afghanistan',
        code: 'AF',
        calling_code: '+93',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagal.png',
        name: 'Albania',
        code: 'AL',
        calling_code: '+355',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagdz.png',
        name: 'Algeria',
        code: 'DZ',
        calling_code: '+213',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagas.png',
        name: 'American Samoa',
        code: 'AS',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagad.png',
        name: 'Andorra',
        code: 'AD',
        calling_code: '+376',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagao.png',
        name: 'Angola',
        code: 'AO',
        calling_code: '+244',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagai.png',
        name: 'Anguilla',
        code: 'AI',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagaq.png',
        name: 'Antarctica',
        code: 'AQ',
        calling_code: '+620',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagag.png',
        name: 'Antigua and Barbuda',
        code: 'AG',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagar.png',
        name: 'Argentina',
        code: 'AR',
        calling_code: '+54',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagam.png',
        name: 'Armenia',
        code: 'AM',
        calling_code: '+374',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagaw.png',
        name: 'Aruba',
        code: 'AW',
        calling_code: '+297',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagau.png',
        name: 'Australia',
        code: 'AU',
        calling_code: '+61',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagat.png',
        name: 'Austria',
        code: 'AT',
        calling_code: '+43',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagaz.png',
        name: 'Azerbaijan',
        code: 'AZ',
        calling_code: '+994',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbs.png',
        name: 'Bahamas',
        code: 'BS',
        calling_code: '+1 242',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbh.png',
        name: 'Bahrain',
        code: 'BH',
        calling_code: '+973',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbd.png',
        name: 'Bangladesh',
        code: 'BD',
        calling_code: '+880',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbb.png',
        name: 'Barbados',
        code: 'BB',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagby.png',
        name: 'Belarus',
        code: 'BY',
        calling_code: '+375',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbe.png',
        name: 'Belgium',
        code: 'BE',
        calling_code: '+32',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbz.png',
        name: 'Belize',
        code: 'BZ',
        calling_code: '+501',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbj.png',
        name: 'Benin',
        code: 'BJ',
        calling_code: '+229',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbm.png',
        name: 'Bermuda',
        code: 'BM',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbt.png',
        name: 'Bhutan',
        code: 'BT',
        calling_code: '+975',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbo.png',
        name: 'Bolivia, Plurinational State of',
        code: 'BO',
        calling_code: '+591',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagba.png',
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        calling_code: '+387',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbw.png',
        name: 'Botswana',
        code: 'BW',
        calling_code: '+267',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbv.png',
        name: 'Bouvet Island',
        code: 'BV',
        calling_code: '+74',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbr.png',
        name: 'Brazil',
        code: 'BR',
        calling_code: '+55',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagio.png',
        name: 'British Indian Ocean Territory',
        code: 'IO',
        calling_code: '+246',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbn.png',
        name: 'Brunei Darussalam',
        code: 'BN',
        calling_code: '+673',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbg.png',
        name: 'Bulgaria',
        code: 'BG',
        calling_code: '+359',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbf.png',
        name: 'Burkina Faso',
        code: 'BF',
        calling_code: '+226',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbi.png',
        name: 'Burundi',
        code: 'BI',
        calling_code: '+257',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkh.png',
        name: 'Cambodia',
        code: 'KH',
        calling_code: '+855',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcm.png',
        name: 'Cameroon',
        code: 'CM',
        calling_code: '+237',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagca.png',
        name: 'Canada',
        code: 'CA',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcv.png',
        name: 'Cape Verde',
        code: 'CV',
        calling_code: '+238',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbq.png',
        name: 'Caribbean Netherlands',
        code: 'BQ',
        calling_code: '+599',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagky.png',
        name: 'Cayman Islands',
        code: 'KY',
        calling_code: '+345',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcf.png',
        name: 'Central African Republic',
        code: 'CF',
        calling_code: '+236',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtd.png',
        name: 'Chad',
        code: 'TD',
        calling_code: '+235',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcl.png',
        name: 'Chile',
        code: 'CL',
        calling_code: '+56',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcn.png',
        name: 'China',
        code: 'CN',
        calling_code: '+86',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcx.png',
        name: 'Christmas Island',
        code: 'CX',
        calling_code: '+61',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcc.png',
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        calling_code: '+61',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagco.png',
        name: 'Colombia',
        code: 'CO',
        calling_code: '+57',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkm.png',
        name: 'Comoros',
        code: 'KM',
        calling_code: '+269',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcg.png',
        name: 'Congo',
        code: 'CG',
        calling_code: '+242',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcd.png',
        name: 'Congo, the Democratic Republic of the',
        code: 'CD',
        calling_code: '+243',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagck.png',
        name: 'Cook Islands',
        code: 'CK',
        calling_code: '+682',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcr.png',
        name: 'Costa Rica',
        code: 'CR',
        calling_code: '+506',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaghr.png',
        name: 'Croatia',
        code: 'HR',
        calling_code: '+385',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcu.png',
        name: 'Cuba',
        code: 'CU',
        calling_code: '+53',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcw.png',
        name: 'Curaçao',
        code: 'CW',
        calling_code: '+599',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcy.png',
        name: 'Cyprus',
        code: 'CY',
        calling_code: '+357',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagcz.png',
        name: 'Czech Republic',
        code: 'CZ',
        calling_code: '+420',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagci.png',
        name: "Côte d'Ivoire",
        code: 'CI',
        calling_code: '+384',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagdk.png',
        name: 'Denmark',
        code: 'DK',
        calling_code: '+45',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagdj.png',
        name: 'Djibouti',
        code: 'DJ',
        calling_code: '+253',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagdm.png',
        name: 'Dominica',
        code: 'DM',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagdo.png',
        name: 'Dominican Republic',
        code: 'DO',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagec.png',
        name: 'Ecuador',
        code: 'EC',
        calling_code: '+593',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflageg.png',
        name: 'Egypt',
        code: 'EG',
        calling_code: '+20',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsv.png',
        name: 'El Salvador',
        code: 'SV',
        calling_code: '+503',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggb-eng.png',
        name: 'England',
        code: 'GB-ENG',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggq.png',
        name: 'Equatorial Guinea',
        code: 'GQ',
        calling_code: '+240',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflager.png',
        name: 'Eritrea',
        code: 'ER',
        calling_code: '+291',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagee.png',
        name: 'Estonia',
        code: 'EE',
        calling_code: '+372',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaget.png',
        name: 'Ethiopia',
        code: 'ET',
        calling_code: '+251',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflageu.png',
        name: 'Europe',
        code: 'EU',
        calling_code: '+',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfk.png',
        name: 'Falkland Islands (Malvinas)',
        code: 'FK',
        calling_code: '+500',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfo.png',
        name: 'Faroe Islands',
        code: 'FO',
        calling_code: '+298',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfj.png',
        name: 'Fiji',
        code: 'FJ',
        calling_code: '+679',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfi.png',
        name: 'Finland',
        code: 'FI',
        calling_code: '+358',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfr.png',
        name: 'France',
        code: 'FR',
        calling_code: '+33',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggf.png',
        name: 'French Guiana',
        code: 'GF',
        calling_code: '+594',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpf.png',
        name: 'French Polynesia',
        code: 'PF',
        calling_code: '+689',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtf.png',
        name: 'French Southern Territories',
        code: 'TF',
        calling_code: '+260',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagga.png',
        name: 'Gabon',
        code: 'GA',
        calling_code: '+241',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggm.png',
        name: 'Gambia',
        code: 'GM',
        calling_code: '+220',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagge.png',
        name: 'Georgia',
        code: 'GE',
        calling_code: '+995',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagde.png',
        name: 'Germany',
        code: 'DE',
        calling_code: '+49',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggh.png',
        name: 'Ghana',
        code: 'GH',
        calling_code: '+233',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggi.png',
        name: 'Gibraltar',
        code: 'GI',
        calling_code: '+350',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggr.png',
        name: 'Greece',
        code: 'GR',
        calling_code: '+30',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggl.png',
        name: 'Greenland',
        code: 'GL',
        calling_code: '+299',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggd.png',
        name: 'Grenada',
        code: 'GD',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggp.png',
        name: 'Guadeloupe',
        code: 'GP',
        calling_code: '+590',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggu.png',
        name: 'Guam',
        code: 'GU',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggt.png',
        name: 'Guatemala',
        code: 'GT',
        calling_code: '+502',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggg.png',
        name: 'Guernsey',
        code: 'GG',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggn.png',
        name: 'Guinea',
        code: 'GN',
        calling_code: '+244',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggw.png',
        name: 'Guinea-Bissau',
        code: 'GW',
        calling_code: '+245',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggy.png',
        name: 'Guyana',
        code: 'GY',
        calling_code: '+595',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaght.png',
        name: 'Haiti',
        code: 'HT',
        calling_code: '+509',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaghm.png',
        name: 'Heard Island and McDonald Islands',
        code: 'HM',
        calling_code: '+334',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagva.png',
        name: 'Holy See (Vatican City State)',
        code: 'VA',
        calling_code: '+379',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaghn.png',
        name: 'Honduras',
        code: 'HN',
        calling_code: '+504',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaghk.png',
        name: 'Hong Kong',
        code: 'HK',
        calling_code: '+852',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaghu.png',
        name: 'Hungary',
        code: 'HU',
        calling_code: '+36',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagis.png',
        name: 'Iceland',
        code: 'IS',
        calling_code: '+354',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagin.png',
        name: 'India',
        code: 'IN',
        calling_code: '+91',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagid.png',
        name: 'Indonesia',
        code: 'ID',
        calling_code: '+62',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagir.png',
        name: 'Iran, Islamic Republic of',
        code: 'IR',
        calling_code: '+98',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagiq.png',
        name: 'Iraq',
        code: 'IQ',
        calling_code: '+964',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagie.png',
        name: 'Ireland',
        code: 'IE',
        calling_code: '+353',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagim.png',
        name: 'Isle of Man',
        code: 'IM',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagil.png',
        name: 'Israel',
        code: 'IL',
        calling_code: '+972',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagit.png',
        name: 'Italy',
        code: 'IT',
        calling_code: '+39',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagjm.png',
        name: 'Jamaica',
        code: 'JM',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagjp.png',
        name: 'Japan',
        code: 'JP',
        calling_code: '+81',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagje.png',
        name: 'Jersey',
        code: 'JE',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagjo.png',
        name: 'Jordan',
        code: 'JO',
        calling_code: '+962',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkz.png',
        name: 'Kazakhstan',
        code: 'KZ',
        calling_code: '+7 7',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagke.png',
        name: 'Kenya',
        code: 'KE',
        calling_code: '+254',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagki.png',
        name: 'Kiribati',
        code: 'KI',
        calling_code: '+686',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkp.png',
        name: "Korea, Democratic People's Republic of",
        code: 'KP',
        calling_code: '+850',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkr.png',
        name: 'Korea, Republic of',
        code: 'KR',
        calling_code: '+82',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagxk.png',
        name: 'Kosovo',
        code: 'XK',
        calling_code: '+383',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkw.png',
        name: 'Kuwait',
        code: 'KW',
        calling_code: '+965',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkg.png',
        name: 'Kyrgyzstan',
        code: 'KG',
        calling_code: '+996',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagla.png',
        name: "Lao People's Democratic Republic",
        code: 'LA',
        calling_code: '+856',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglv.png',
        name: 'Latvia',
        code: 'LV',
        calling_code: '+371',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglb.png',
        name: 'Lebanon',
        code: 'LB',
        calling_code: '+961',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagls.png',
        name: 'Lesotho',
        code: 'LS',
        calling_code: '+266',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglr.png',
        name: 'Liberia',
        code: 'LR',
        calling_code: '+231',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagly.png',
        name: 'Libya',
        code: 'LY',
        calling_code: '+218',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagli.png',
        name: 'Liechtenstein',
        code: 'LI',
        calling_code: '+423',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglt.png',
        name: 'Lithuania',
        code: 'LT',
        calling_code: '+370',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglu.png',
        name: 'Luxembourg',
        code: 'LU',
        calling_code: '+352',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmo.png',
        name: 'Macao',
        code: 'MO',
        calling_code: '+853',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmk.png',
        name: 'Macedonia, the former Yugoslav Republic of',
        code: 'MK',
        calling_code: '+389',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmg.png',
        name: 'Madagascar',
        code: 'MG',
        calling_code: '+261',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmw.png',
        name: 'Malawi',
        code: 'MW',
        calling_code: '+265',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmy.png',
        name: 'Malaysia',
        code: 'MY',
        calling_code: '+60',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmv.png',
        name: 'Maldives',
        code: 'MV',
        calling_code: '+960',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagml.png',
        name: 'Mali',
        code: 'ML',
        calling_code: '+223',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmt.png',
        name: 'Malta',
        code: 'MT',
        calling_code: '+356',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmh.png',
        name: 'Marshall Islands',
        code: 'MH',
        calling_code: '+692',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmq.png',
        name: 'Martinique',
        code: 'MQ',
        calling_code: '+596',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmr.png',
        name: 'Mauritania',
        code: 'MR',
        calling_code: '+222',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmu.png',
        name: 'Mauritius',
        code: 'MU',
        calling_code: '+230',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagyt.png',
        name: 'Mayotte',
        code: 'YT',
        calling_code: '+262',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmx.png',
        name: 'Mexico',
        code: 'MX',
        calling_code: '+52',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagfm.png',
        name: 'Micronesia, Federated States of',
        code: 'FM',
        calling_code: '+691',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmd.png',
        name: 'Moldova, Republic of',
        code: 'MD',
        calling_code: '+373',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmc.png',
        name: 'Monaco',
        code: 'MC',
        calling_code: '+377',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmn.png',
        name: 'Mongolia',
        code: 'MN',
        calling_code: '+976',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagme.png',
        name: 'Montenegro',
        code: 'ME',
        calling_code: '+382',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagms.png',
        name: 'Montserrat',
        code: 'MS',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagma.png',
        name: 'Morocco',
        code: 'MA',
        calling_code: '+212',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmz.png',
        name: 'Mozambique',
        code: 'MZ',
        calling_code: '+258',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmm.png',
        name: 'Myanmar',
        code: 'MM',
        calling_code: '+95',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagna.png',
        name: 'Namibia',
        code: 'NA',
        calling_code: '+264',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnr.png',
        name: 'Nauru',
        code: 'NR',
        calling_code: '+674',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnp.png',
        name: 'Nepal',
        code: 'NP',
        calling_code: '+977',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnl.png',
        name: 'Netherlands',
        code: 'NL',
        calling_code: '+31',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagan.png',
        name: 'Netherlands Antilles',
        code: 'AN',
        calling_code: '+599',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnc.png',
        name: 'New Caledonia',
        code: 'NC',
        calling_code: '+687',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnz.png',
        name: 'New Zealand',
        code: 'NZ',
        calling_code: '+64',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagni.png',
        name: 'Nicaragua',
        code: 'NI',
        calling_code: '+505',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagne.png',
        name: 'Niger',
        code: 'NE',
        calling_code: '+227',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagng.png',
        name: 'Nigeria',
        code: 'NG',
        calling_code: '+234',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnu.png',
        name: 'Niue',
        code: 'NU',
        calling_code: '+683',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagnf.png',
        name: 'Norfolk Island',
        code: 'NF',
        calling_code: '+672',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggb-nir.png',
        name: 'Northern Ireland',
        code: 'GB-NIR',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmp.png',
        name: 'Northern Mariana Islands',
        code: 'MP',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagno.png',
        name: 'Norway',
        code: 'NO',
        calling_code: '+47',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagom.png',
        name: 'Oman',
        code: 'OM',
        calling_code: '+968',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpk.png',
        name: 'Pakistan',
        code: 'PK',
        calling_code: '+92',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpw.png',
        name: 'Palau',
        code: 'PW',
        calling_code: '+680',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagps.png',
        name: 'Palestine',
        code: 'PS',
        calling_code: '+970',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpa.png',
        name: 'Panama',
        code: 'PA',
        calling_code: '+507',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpg.png',
        name: 'Papua New Guinea',
        code: 'PG',
        calling_code: '+675',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpy.png',
        name: 'Paraguay',
        code: 'PY',
        calling_code: '+595',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpe.png',
        name: 'Peru',
        code: 'PE',
        calling_code: '+51',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagph.png',
        name: 'Philippines',
        code: 'PH',
        calling_code: '+63',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpn.png',
        name: 'Pitcairn',
        code: 'PN',
        calling_code: '+872',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpl.png',
        name: 'Poland',
        code: 'PL',
        calling_code: '+48',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpt.png',
        name: 'Portugal',
        code: 'PT',
        calling_code: '+351',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpr.png',
        name: 'Puerto Rico',
        code: 'PR',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagqa.png',
        name: 'Qatar',
        code: 'QA',
        calling_code: '+974',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagro.png',
        name: 'Romania',
        code: 'RO',
        calling_code: '+40',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagru.png',
        name: 'Russian Federation',
        code: 'RU',
        calling_code: '+7',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagrw.png',
        name: 'Rwanda',
        code: 'RW',
        calling_code: '+250',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagre.png',
        name: 'Réunion',
        code: 'RE',
        calling_code: '+262',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagbl.png',
        name: 'Saint Barthélemy',
        code: 'BL',
        calling_code: '+590',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsh.png',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'SH',
        calling_code: '+290',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagkn.png',
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglc.png',
        name: 'Saint Lucia',
        code: 'LC',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagmf.png',
        name: 'Saint Martin',
        code: 'MF',
        calling_code: '+590',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagpm.png',
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        calling_code: '+508',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagvc.png',
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagws.png',
        name: 'Samoa',
        code: 'WS',
        calling_code: '+685',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsm.png',
        name: 'San Marino',
        code: 'SM',
        calling_code: '+378',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagst.png',
        name: 'Sao Tome and Principe',
        code: 'ST',
        calling_code: '+239',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsa.png',
        name: 'Saudi Arabia',
        code: 'SA',
        calling_code: '+966',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggb-sct.png',
        name: 'Scotland',
        code: 'GB-SCT',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsn.png',
        name: 'Senegal',
        code: 'SN',
        calling_code: '+221',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagrs.png',
        name: 'Serbia',
        code: 'RS',
        calling_code: '+381',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsc.png',
        name: 'Seychelles',
        code: 'SC',
        calling_code: '+248',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsl.png',
        name: 'Sierra Leone',
        code: 'SL',
        calling_code: '+232',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsg.png',
        name: 'Singapore',
        code: 'SG',
        calling_code: '+65',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsx.png',
        name: 'Sint Maarten (Dutch part)',
        code: 'SX',
        calling_code: '+721',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsk.png',
        name: 'Slovakia',
        code: 'SK',
        calling_code: '+421',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsi.png',
        name: 'Slovenia',
        code: 'SI',
        calling_code: '+386',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsb.png',
        name: 'Solomon Islands',
        code: 'SB',
        calling_code: '+677',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagso.png',
        name: 'Somalia',
        code: 'SO',
        calling_code: '+252',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagza.png',
        name: 'South Africa',
        code: 'ZA',
        calling_code: '+27',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggs.png',
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        calling_code: '+500',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagss.png',
        name: 'South Sudan',
        code: 'SS',
        calling_code: '+211',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflages.png',
        name: 'Spain',
        code: 'ES',
        calling_code: '+34',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaglk.png',
        name: 'Sri Lanka',
        code: 'LK',
        calling_code: '+94',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsd.png',
        name: 'Sudan',
        code: 'SD',
        calling_code: '+249',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsr.png',
        name: 'Suriname',
        code: 'SR',
        calling_code: '+597',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsj.png',
        name: 'Svalbard and Jan Mayen Islands',
        code: 'SJ',
        calling_code: '+47',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsz.png',
        name: 'Swaziland',
        code: 'SZ',
        calling_code: '+268',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagse.png',
        name: 'Sweden',
        code: 'SE',
        calling_code: '+46',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagch.png',
        name: 'Switzerland',
        code: 'CH',
        calling_code: '+41',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagsy.png',
        name: 'Syrian Arab Republic',
        code: 'SY',
        calling_code: '+963',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtw.png',
        name: 'Taiwan',
        code: 'TW',
        calling_code: '+886',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtj.png',
        name: 'Tajikistan',
        code: 'TJ',
        calling_code: '+992',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtz.png',
        name: 'Tanzania, United Republic of',
        code: 'TZ',
        calling_code: '+255',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagth.png',
        name: 'Thailand',
        code: 'TH',
        calling_code: '+66',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtl.png',
        name: 'Timor-Leste',
        code: 'TL',
        calling_code: '+670',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtg.png',
        name: 'Togo',
        code: 'TG',
        calling_code: '+228',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtk.png',
        name: 'Tokelau',
        code: 'TK',
        calling_code: '+690',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagto.png',
        name: 'Tonga',
        code: 'TO',
        calling_code: '+676',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtt.png',
        name: 'Trinidad and Tobago',
        code: 'TT',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtn.png',
        name: 'Tunisia',
        code: 'TN',
        calling_code: '+216',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtr.png',
        name: 'Turkey',
        code: 'TR',
        calling_code: '+90',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtm.png',
        name: 'Turkmenistan',
        code: 'TM',
        calling_code: '+993',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtc.png',
        name: 'Turks and Caicos Islands',
        code: 'TC',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagtv.png',
        name: 'Tuvalu',
        code: 'TV',
        calling_code: '+688',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagum.png',
        name: 'US Minor Outlying Islands',
        code: 'UM',
        calling_code: '+246',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagug.png',
        name: 'Uganda',
        code: 'UG',
        calling_code: '+256',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagua.png',
        name: 'Ukraine',
        code: 'UA',
        calling_code: '+380',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagae.png',
        name: 'United Arab Emirates',
        code: 'AE',
        calling_code: '+971',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggb.png',
        name: 'United Kingdom',
        code: 'GB',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagus.png',
        name: 'United States',
        code: 'US',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaguy.png',
        name: 'Uruguay',
        code: 'UY',
        calling_code: '+598',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaguz.png',
        name: 'Uzbekistan',
        code: 'UZ',
        calling_code: '+998',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagvu.png',
        name: 'Vanuatu',
        code: 'VU',
        calling_code: '+678',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagve.png',
        name: 'Venezuela, Bolivarian Republic of',
        code: 'VE',
        calling_code: '+58',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagvn.png',
        name: 'Viet Nam',
        code: 'VN',
        calling_code: '+84',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagvg.png',
        name: 'Virgin Islands, British',
        code: 'VG',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagvi.png',
        name: 'Virgin Islands, U.S.',
        code: 'VI',
        calling_code: '+1',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflaggb-wls.png',
        name: 'Wales',
        code: 'GB-WLS',
        calling_code: '+44',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagwf.png',
        name: 'Wallis and Futuna Islands',
        code: 'WF',
        calling_code: '+681',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflageh.png',
        name: 'Western Sahara',
        code: 'EH',
        calling_code: '+212',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagye.png',
        name: 'Yemen',
        code: 'YE',
        calling_code: '+967',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagzm.png',
        name: 'Zambia',
        code: 'ZM',
        calling_code: '+260',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagzw.png',
        name: 'Zimbabwe',
        code: 'ZW',
        calling_code: '+263',
    },
    {
        url: 'https://d259eyklzsv1lk.cloudfront.net/cflagax.png',
        name: 'Åland Islands',
        code: 'AX',
        calling_code: '+358',
    },
]
