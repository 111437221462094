import React from 'react'
import Text from '@component/Text/Text'
import {useTranslation} from 'next-i18next'
import {BotStatus} from '@api/key/copyfolio/getKeyCopyfolio'
import IconClose from '@svg/common/ic_close.svg'

interface IProps {
    status: BotStatus
    className?: string
}

const CopyableTag: React.FC<IProps> = ({status, className}) => {
    const {t} = useTranslation()

    const renderText = () => {
        switch (status) {
            case 'running':
                return t('botStatus.running')
            case 'paused':
            case 'close':
                return t('botStatus.close')
        }
    }

    const getTextStyle = () => {
        switch (status) {
            case 'running':
                return 'text-blue dark:text-dark_blue'
            case 'paused':
            case 'close':
                return 'text-red dark:text-dark_red'
        }
    }

    return (
        <div
            className={`flex justify-center items-center rounded-full px-[10px] py-[3.5px] bg-white dark:bg-bg_dark_white03 whitespace-nowrap ${className}`}>
            {status === 'running' ? (
                <div className={'w-[7px] flex-none h-[7px] rounded-full bg-blue dark:bg-dark_blue mr-[7px]'} />
            ) : (
                <IconClose className={'w-[12px] flex-none fill-red dark:fill-dark_red mr-[3px]'} />
            )}
            <Text className={`text-body_comm font-bold ${getTextStyle()}`}>{renderText()}</Text>
        </div>
    )
}

export default CopyableTag
