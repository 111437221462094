import {useEffect} from 'react'
import {isNotEmpty} from '@util/strings'
import {amplitudeConfig} from '@constant/config'
import * as amplitude from '@amplitude/analytics-browser'

export interface ILogProps {
    pageName?: string
    option?: any
}

const useScreenLog = (params: ILogProps = {}) => {
    const {pageName, option} = params

    useEffect(() => {
        if (isNotEmpty(pageName)) event(`[SCREEN] ${pageName}`, option)
    }, [pageName])

    const event = (eventName: string, option?: any) => {
        if (amplitudeConfig.isSupportLog === true) {
            if (option) {
                amplitude.logEvent(eventName, option as Record<string, any>)
            } else {
                amplitude?.track(eventName)
            }
        }
    }

    return {event}
}

export default useScreenLog
