import React, {useImperativeHandle, useRef, useState} from 'react'
import Text from '@component/Text/Text'
import FailedIcon from '@svg/common/ic_noti_error.svg'
import IconSuccess from '@svg/common/ic_noti_confirm.svg'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import WizButton from '@component/buttons/WizButton'
import {LeagueJoinApiStatus} from '@api/rank/league/league'
import useNavigation from '@hook/useNavigation'

type Screen = 'success' | 'failed'

interface Props {
    callbackFunc?: (e?: any) => void
}

const LeagueJoinStatusModal = ({callbackFunc}: Props, ref: any) => {
    const {t} = useTranslation()
    const [screen, setScreen] = useState('')
    const [error, setError] = useState<string | undefined>('')
    const [status, setStatus] = useState<number | undefined>(0)
    const [title, setTitle] = useState<string | undefined>('')
    const {goApi} = useNavigation()

    const modalRef = useRef(null)
    useImperativeHandle(ref, () => ({
        show: ({screen, error, status, title}: {screen: Screen; error: string; status: number; title: string}) => {
            setScreen(screen)
            setError(error)
            setStatus(status)
            setTitle(title)

            if (screen === 'failed' && !error && !status) {
                return
            }
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))

    const onClickMoveToAPIListPage = () => {
        goApi()
        modalRef?.current.close()
    }

    return (
        <WizContentModal
            size={'400'}
            ref={modalRef}
            onCloseCallback={() => {
                callbackFunc && callbackFunc()
            }}>
            {screen === 'success' && (
                <div className={'space-y-[10px] mb-[20px] flex flex-col items-center'}>
                    <IconSuccess className={'w-[50px] h-[50px] fill-primary dark:fill-dark_primary'} />
                    <Text className={'text-h3 text-center text-gray01 dark:text-dark_gray01'}>
                        {t('league.leaderboard.modal.success.title')}
                    </Text>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center'}>
                        {t('league.leaderboard.modal.success.message')}
                    </Text>
                </div>
            )}
            {screen === 'failed' && (
                <>
                    <div className={'space-y-[10px] mb-[20px] flex flex-col items-center'}>
                        <FailedIcon className={'w-[50px] h-[50px] fill-red dark:fill-dark_red'} />
                        <Text className={'text-h3 text-center text-gray01 dark:text-dark_gray01'}>
                            {title ?? t('league.leaderboard.modal.failed.title')}
                        </Text>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02 text-center'}>{error}</Text>
                    </div>
                    {status === LeagueJoinApiStatus.LeagueExchange && (
                        <WizButton
                            className={'w-full'}
                            text={t('league.leaderboard.modal.failed.connect.exchange.button.text')}
                            buttonType={'primary'}
                            onClick={onClickMoveToAPIListPage}
                        />
                    )}
                </>
            )}
        </WizContentModal>
    )
}

export default React.forwardRef(LeagueJoinStatusModal)
